import React, { useState } from 'react';
import { View, TouchableHighlight, Platform, ActivityIndicator, Alert, TouchableOpacity, Linking } from 'react-native';
import { Image, Text, Icon } from '@components';
import { BaseColor } from '@config';
import styles from './styles';
import { Placeholder, PlaceholderLine, PlaceholderMedia, Fade } from 'rn-placeholder';
import { connect, useDispatch } from 'react-redux';
import Menu from '../Menu';
import { BaseStyle, Images } from '../../config';
import { userActions } from '../../redux/actions';
import { getImageUrl } from '../../helpers/functions';

function UserListItem(props) {
    const { item, loading, style, navigation, deleteUser, user } = props;
    const [pressed, setpressed] = useState(false);
    const deleteUserAlert = (id) => {
        Alert.alert({
            title: item.lastname + ' löschen',
            message: 'Möchten Sie diesen Benutzer löschen?',
            type: 'error',
            action: [
                { text: 'Löschen', onPress: () => deleteUser(id) },
                { text: 'Abbrechen', onPress: () => {} },
            ],
        });
    };
    const adminAction = () => (
        <View style={{ top: 5, right: 5, position: 'absolute' }}>
            <Menu
                activator={() => (
                    <View
                        style={[
                            {
                                //backgroundColor: BaseColor.whiteColor,
                                height: 40,
                                width: 40,
                                //borderRadius: 30,
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                            //Platform.OS == 'android' && BaseStyle.boxWithShadowBottom,
                        ]}
                    >
                        <Icon name="more-vert" type="mi" size={20} color={BaseColor.text} enableRTL={true} />
                    </View>
                )}
                actionItems={[
                    {
                        text: 'Bearbeiten',
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    width: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon name="edit" type="ad" size={20} color={BaseColor.text} />
                            </View>
                        ),
                        action: () => navigation.navigate({ name: 'UserEdit', params: { user: item } }),
                    },
                    {
                        text: 'Löschen',
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    width: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon name="delete" type="ad" size={20} color={BaseColor.text} />
                            </View>
                        ),
                        action: () => deleteUserAlert(item.id),
                    },
                ]}
            />
        </View>
    );
    const renderGrid = () => {
        if (loading) {
            return (
                <View style={[style]}>
                    <Placeholder Animation={Fade}>
                        <View style={{ flexDirection: 'row' }}>
                            <View>
                                <PlaceholderMedia style={[styles.girdImage]} />
                            </View>
                            <View style={{ flex: 1, paddingLeft: 10 }}>
                                <PlaceholderLine style={{ height: 50, borderRadius: 0 }} />
                            </View>
                        </View>
                    </Placeholder>
                </View>
            );
        }
        return (
            <View style={{ marginBottom: 13 }}>
                <TouchableHighlight
                    underlayColor={'#00000010'}
                    activeOpacity={1}
                    style={{
                        overflow: 'hidden',
                        paddingHorizontal: 15,
                        paddingVertical: 10,
                        backgroundColor: BaseColor.whiteColor,
                        borderBottomColor: BaseColor.grayTransparent,
                        borderBottomWidth: 1,
                    }}
                    onPressIn={() => setpressed(true)}
                    onPressOut={() => setpressed(false)}
                    onPress={() =>
                        navigation.navigate('UserDetails', {
                            user: item,
                        })
                    }
                >
                    <View>
                        <View style={{ flexDirection: 'row' }}>
                            {/* <View
                                style={{
                                    height: 50,
                                    width: 50,
                                    borderRadius: 30,
                                    overflow: 'hidden',
                                    borderWidth: 2,
                                    borderColor: BaseColor.grayTransparent,
                                    backgroundColor: BaseColor.grayTransparent
                                }}
                            >
                                {item.logo ? (
                                    <Image style={styles.girdImage} source={{ uri: getImageUrl(`${item.logo.path}/${item.logo.name}`) }} />
                                ) : (
                                    <View
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: '#fff',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <Icon name="user" type="ad" size={18} color={BaseColor.grayColor} />
                                    </View>
                                )}
                            </View> */}
                            <View style={{ flex: 1 }}>
                                <Text>
                                    {`${item.id} -`} {item.firstname} {item.lastname}
                                </Text>
                                <Text footnote grayColor>
                                    {item.roles[0]?.name} {item.roles[0]?.value == 2 ? `(${item.client?.name})` : ''}
                                </Text>
                                <View
                                    style={{
                                        flexDirection: 'row',
                                    }}
                                >
                                    <View
                                        style={{
                                            flexDirection: 'row',
                                            marginTop: 10,

                                            borderRadius: 20,
                                            backgroundColor: BaseColor.grayLight,
                                        }}
                                    >
                                        <TouchableOpacity
                                            style={{
                                                paddingHorizontal: 15,
                                                paddingVertical: 5,
                                                borderRightWidth: 1,
                                                borderColor: BaseColor.grayTransparent,
                                            }}
                                            onPress={() => item.phonenumber && Linking.openURL(`tel:${item.phonenumber}`)}
                                        >
                                            <Icon name="phone" type="ad" size={18} color={item.phonenumber ? 'black' : BaseColor.grayTransparent} />
                                        </TouchableOpacity>
                                        <TouchableOpacity
                                            style={{ paddingVertical: 5, paddingHorizontal: 15 }}
                                            onPress={() => item.email && Linking.openURL(`mailto:${item.email}`)}
                                        >
                                            <Icon name="mail" type="ad" size={18} color={item.email ? 'black' : BaseColor.grayTransparent} />
                                        </TouchableOpacity>
                                    </View>
                                    <View style={{ flex: 1 }}></View>
                                </View>
                            </View>
                        </View>
                    </View>
                </TouchableHighlight>
                {(item.id !== 19 || user.id == 19) && adminAction()}
                {item.deleting && (
                    <View style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
                        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: '#00000080' }}>
                            <ActivityIndicator size={'large'} color={BaseColor.whiteColor} />
                        </View>
                    </View>
                )}
                <View
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        height: '100%',
                        width: 3,
                        backgroundColor:
                            item.roles[0]?.value == 1 ? BaseColor.primary : item.roles[0]?.value == 2 ? BaseColor.blueColor : BaseColor.greenColor,
                    }}
                ></View>
                {/* {pressed && (
                    <View style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', transform: [{ scale: 1.1 }] }}>
                        <View style={{ flex: 1, backgroundColor: '#00000010' }}></View>
                    </View>
                )} */}
            </View>
        );
    };

    return renderGrid();
}

function mapState(state) {
    const { user } = state.users;
    //const { deleting } = state.users?.all;
    return { user };
}

const actionCreators = {
    deleteUser: userActions.delete,
};

export default connect(mapState, actionCreators)(UserListItem);
