import React, { useEffect, useRef, useState } from 'react';
import { Animated, Platform, TouchableOpacity, Vibration, View } from 'react-native';
import { Button, Icon, Text, TextInput } from '../../components';
import { BaseColor } from '../../config';
import { Switch } from 'react-native-gesture-handler';
import Menu from '../../components/Menu';
import { showMessage } from 'react-native-flash-message';
import { scrollToId } from '../../helpers/functions';

export default function FormDropdownInput({ submitedInput, setInput, isLast, editable }) {
    const [state, setState] = useState({ ...submitedInput, itemOrder: 0 });
    const animationRef = useRef(new Animated.Value(0));

    const highlight = () => {
        scrollToId('submitedInput' + submitedInput.id);
        Vibration.vibrate(100);
        Animated.sequence([
            Animated.timing(animationRef.current, {
                toValue: 1,
                // set the duration
                duration: 500,
                useNativeDriver: false,
            }),
            // delay is optional
            Animated.delay(1000),
            Animated.timing(animationRef.current, {
                toValue: 0,
                duration: 500,
                // useNativeDriver:true, might not work with the all properties that you need to animate. true might improve animation performance
                useNativeDriver: false,
            }),
        ]).start();
    };
    useEffect(() => {
        if (editable && submitedInput.error) highlight();
    }, [submitedInput.checkInput]);
    return (
        <Animated.View
            style={{
                borderBottomColor: BaseColor.grayTransparent,
                borderBottomWidth: isLast ? 0 : 1,
                paddingBottom: 15,
                paddingTop: 10,
                paddingHorizontal: 15,
                backgroundColor: animationRef.current?.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['#fff', '#ccc'],
                }),
            }}
        >
            <div id={'submitedInput' + submitedInput.id}>
                <View style={{ paddingBottom: 5 }}>
                    <Text>
                        {submitedInput.input?.label ?? 'Überschrift'}
                        {submitedInput.input?.required && ' *'}
                    </Text>
                    {submitedInput.input?.description && (
                        <Text grayColor footnote>
                            {submitedInput.input?.description}
                        </Text>
                    )}
                </View>
                <Menu
                    activator={() => (
                        <View
                            style={[
                                {
                                    backgroundColor: BaseColor.card,
                                    height: 40,
                                    //width: 40,
                                    borderRadius: 0,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    padding: 10,
                                },
                                //Platform.OS == 'android' && BaseStyle.boxWithShadowBottom,
                            ]}
                        >
                            <View style={{ flex: 1 }}>
                                {submitedInput.value ? <Text>{submitedInput.value}</Text> : <Text grayColor>{submitedInput.input?.placeholder}</Text>}
                            </View>
                            <View>
                                <Icon name="down" type="ad" size={15} color={BaseColor.grayColor} enableRTL={true} />
                            </View>
                        </View>
                    )}
                    actionItems={submitedInput.input?.items?.map((item) => ({
                        text: item.value,
                        disable: !editable,
                        action: () => setInput({ value: item.value, error: false }),
                    }))}
                />
                {submitedInput?.error && (
                    <Text errorColor footnote>
                        Feld erforderlich
                    </Text>
                )}
            </div>
        </Animated.View>
    );
}
