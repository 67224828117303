export const postConstants = {
    GETALL_REQUEST: 'POSTS_GETALL_REQUEST',
    GETALL_SUCCESS: 'POSTS_GETALL_SUCCESS',
    GETALL_FAILURE: 'POSTS_GETALL_FAILURE',

    GETALLMORE_REQUEST: 'POSTS_GETALLMORE_REQUEST',
    GETALLMORE_SUCCESS: 'POSTS_GETALLMORE_SUCCESS',
    GETALLMORE_FAILURE: 'POSTS_GETALLMORE_FAILURE',

    GETALLURGENT_REQUEST: 'POSTS_GETALLURGENT_REQUEST',
    GETALLURGENT_SUCCESS: 'POSTS_GETALLURGENT_SUCCESS',
    GETALLURGENT_FAILURE: 'POSTS_GETALLURGENT_FAILURE',
    GETALLNEWEST_REQUEST: 'POSTS_GETALLNEWEST_REQUEST',
    GETALLNEWEST_SUCCESS: 'POSTS_GETALLNEWEST_SUCCESS',
    GETALLNEWEST_FAILURE: 'POSTS_GETALLNEWEST_FAILURE',

    GETOWNPOST_REQUEST: 'POSTS_GETOWNPOST_REQUEST',
    GETOWNPOST_SUCCESS: 'POSTS_GETOWNPOST_SUCCESS',
    GETOWNPOST_FAILURE: 'POSTS_GETOWNPOST_FAILURE',

    GETALLTOP_REQUEST: 'POSTS_GETALLTOP_REQUEST',
    GETALLTOP_SUCCESS: 'POSTS_GETALLTOP_SUCCESS',
    GETALLTOP_FAILURE: 'POSTS_GETALLTOP_FAILURE',

    GET_MOSTWANTED_REQUEST: 'POSTS_GET_MOSTWANTED_REQUEST',
    GET_MOSTWANTED_SUCCESS: 'POSTS_GET_MOSTWANTED_SUCCESS',
    GET_MOSTWANTED_FAILURE: 'POSTS_GET_MOSTWANTED_FAILURE',

    GETBYIDSIMILAR_REQUEST: 'POSTS_GETBYIDSIMILAR_REQUEST',
    GETBYIDSIMILAR_SUCCESS: 'POSTS_GETBYIDSIMILAR_SUCCESS',
    GETBYIDSIMILAR_FAILURE: 'POSTS_GETBYIDSIMILAR_FAILURE',

    GETBYID_REQUEST: 'POSTS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'POSTS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'POSTS_GETBYID_FAILURE',

    DELETE_REQUEST: 'POSTS_DELETE_REQUEST',
    DELETE_SUCCESS: 'POSTS_DELETE_SUCCESS',
    DELETE_FAILURE: 'POSTS_DELETE_FAILURE',

    CREATE_REQUEST: 'POSTS_CREATE_REQUEST',
    CREATE_SUCCESS: 'POSTS_CREATE_SUCCESS',
    CREATE_FAILURE: 'POSTS_CREATE_FAILURE',

    UPDATE_REQUEST: 'POSTS_UPDATE_REQUEST',
    UPDATE_SUCCESS: 'POSTS_UPDATE_SUCCESS',
    UPDATE_FAILURE: 'POSTS_UPDATE_FAILURE',

    GETFAVORITE_REQUEST: 'POSTS_GETFAVORITE_REQUEST',
    GETFAVORITE_SUCCESS: 'POSTS_GEFAVORITE_SUCCESS',
    GETFAVORITE_FAILURE: 'POSTS_GEFAVORITE_FAILURE',

    GETMOREFAVORITE_REQUEST: 'POSTS_GETMOREFAVORITE_REQUEST',
    GETMOREFAVORITE_SUCCESS: 'POSTS_GETMOREFAVORITE_SUCCESS',
    GETMOREFAVORITE_FAILURE: 'POSTS_GETMOREFAVORITE_FAILURE',

    GET_MYPOSTS_REQUEST: 'POSTS_GET_MYPOSTS_REQUEST',
    GET_MYPOSTS_SUCCESS: 'POSTS_GET_MYPOSTS_SUCCESS',
    GET_MYPOSTS_FAILURE: 'POSTS_GET_MYPOSTS_FAILLURE',

    GETMORE_MYPOSTS_REQUEST: 'POSTS_GETMORE_MYPOSTS_REQUEST',
    GETMORE_MYPOSTS_SUCCESS: 'POSTS_GETMORE_MYPOSTS_SUCCESS',
    GETMORE_MYPOSTS_FAILURE: 'POSTS_GETMORE_MYPOSTS_FAILLURE',

    ADD_TO_FAVORITE_REQUEST: 'ADD_TO_FAVORITE_REQUEST',
    ADD_TO_FAVORITE_SUCCESS: 'ADD_TO_FAVORITE_SUCCESS',
    ADD_TO_FAVORITE_FAILURE: 'ADD_TO_FAVORITE_FAILURE',
    REMOVE_TO_FAVORITE_REQUEST: 'REMOVE_TO_FAVORITE_REQUEST',
    REMOVE_TO_FAVORITE_SUCCESS: 'REMOVE_TO_FAVORITE_SUCCESS',
    REMOVE_TO_FAVORITE_FAILURE: 'REMOVE_TO_FAVORITE_FAILURE',
    LOGOUT_SUCCESS: 'POSTS_LOGOUT_SUCCESS',
    CHANGE_CURRENT: 'POSTS_CHANGE_CURRENT',
};
