import React, { useRef, useState } from 'react';
import { View, SafeAreaView, KeyboardAvoidingView, TouchableOpacity } from 'react-native';
import { Icon, Text } from '@components';
import { connect } from 'react-redux';
import { BaseColor } from '../../config/theme';
import { AlertComponent, Button, Header, Image, TextInput } from '../../components';
import { BaseStyle } from '../../config';
import { Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import styles from './styles';
import { clientActions } from '../../redux/actions';
import Menu from '../../components/Menu';
import * as ImagePicker from 'expo-image-picker';
import { saveImg } from '../../helpers/apis';
import { showMessage } from 'react-native-flash-message';
import { compressImage, resizeFile } from '../../helpers/functions';
import { Camera } from 'expo-camera';
function NewClient({ navigation, createClient }) {
    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });

    const [state, setState] = useState({
        name: '',
        description: '',
        street: '',
        postalCode: '',
        city: '',
        country: '',
        phonenumber: '',
        email: '',
        logo: true,
        image: null,
    });

    const setForm = (name, value) => {
        setState((s) => ({ ...s, [name]: value }));
    };

    function handleSubmit() {
        if (!(!state.creating && state.name)) {
            setState((s) => ({ ...s, nameError: 'Name erforderlich' }));
            return;
        }
        const { name, description, street, postalCode, city, country, phonenumber, email, logo } = state;
        setForm('creating', true);
        createClient({ name, description, street, postalCode, city, country, phonenumber, email, logo: state.image != null }, uploadImage, () =>
            setForm('creating', false)
        );
    }
    const pickImage = async (event) => {
        const file = event.target.files[0];
        setForm('image', await resizeFile(file));
    };

    const uploadImage = async (client) => {
        if (state.image == null) {
            setForm('creating', false);
            showMessage({ message: 'Der Kunde wurde erfolgreich gespeichert!', icon: 'success' });
            navigation.navigate('Home');
        } else
            await saveImg(
                [
                    {
                        content: state.image,
                        name: client.logo.name,
                    },
                ],
                client.logo.path
            )
                .then((res) => {
                    setForm('creating', false);
                    showMessage({ message: 'Der Kunde wurde erfolgreich gespeichert!', icon: 'success' });
                    navigation.navigate('Home');
                })
                .catch((error) => {
                    console.error(error);
                    setForm('creating', false);
                    showMessage({
                        message: 'Ein unerwarteter Fehler ist aufgetreten! Überprüfen Sie Ihre Verbindung oder versuchen Sie es später erneut',
                        icon: 'warning',
                    });
                    //deletePosts(user?.id, [response.id]);
                    return error;
                });
    };

    const descriptionInput = useRef(null);
    const streetInput = useRef(null);
    const postalCodeInput = useRef(null);
    const cityInput = useRef(null);
    const countryInput = useRef(null);
    const phonenumberInput = useRef(null);
    const emailInput = useRef(null);

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <Header
                title="Kunde hinzufügen"
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1 }}
                >
                    <ScrollView>
                        <View style={{ padding: 20, paddingBottom: 80, alignItems: 'center' }}>
                            <View style={{ width: 100 }}>
                                {state.image != null ? (
                                    <View>
                                        <View
                                            style={{
                                                borderRadius: 50,
                                                borderWidth: 2,
                                                position: 'relative',
                                                borderStyle: 'solid',
                                                borderColor: BaseColor.grayTransparent,
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Image source={URL.createObjectURL(state.image)} style={{ width: 100, height: 100 }} />
                                        </View>
                                        <View
                                            style={{
                                                position: 'absolute',
                                                top: 3,
                                                right: 3,
                                            }}
                                        >
                                            <TouchableOpacity
                                                style={{
                                                    width: 30,
                                                    height: 30,
                                                    borderRadius: 50,
                                                    borderWidth: 2,
                                                    borderRadius: 20,
                                                    backgroundColor: BaseColor.whiteColor,
                                                    borderColor: BaseColor.grayTransparent,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                onPress={() => setForm('image', null)}
                                            >
                                                <Icon name="delete" type="ad" size={15} enableRTL={true} />
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                ) : (
                                    <View>
                                        <TouchableOpacity>
                                            <View
                                                style={{
                                                    borderStyle: 'solid',
                                                    borderColor: '#eee',
                                                    borderWidth: 2,
                                                    borderRadius: 50,
                                                    backgroundColor: BaseColor.grayLight,
                                                    height: 100,
                                                    width: 100,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    overflow: 'hidden',
                                                }}
                                            >
                                                <label
                                                    htmlFor="fileInput"
                                                    style={{ height: '100%', width: '100%', paddingLeft: 60, paddingTop: 35, cursor: 'pointer' }}
                                                >
                                                    <input
                                                        id="fileInput"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={pickImage}
                                                        style={{
                                                            display: 'none',
                                                        }}
                                                    />
                                                    <Icon name="camera-outline" size={30} type="ii" color={BaseColor.text} enableRTL={true} />
                                                </label>
                                            </View>
                                        </TouchableOpacity>
                                    </View>
                                )}
                            </View>
                            <Text semibold grayColor>
                                Logo
                            </Text>
                            <TextInput
                                onChangeText={(v) => setForm('name', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        nameError: '',
                                    });
                                }}
                                placeholder="Name *"
                                success={!state.nameError}
                                value={state.name}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => descriptionInput.current.focus()}
                                textError={state.nameError}
                            />
                            <TextInput
                                inputRef={descriptionInput}
                                onChangeText={(v) => setForm('description', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        descriptionError: '',
                                    });
                                }}
                                placeholder="Beschreibung"
                                success={!state.descriptionError}
                                value={state.description}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => streetInput.current.focus()}
                                textError={state.descriptionError}
                            />
                            <TextInput
                                inputRef={streetInput}
                                onChangeText={(v) => setForm('street', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        streetError: '',
                                    });
                                }}
                                placeholder="Straße"
                                success={!state.streetError}
                                value={state.street}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => postalCodeInput.current.focus()}
                                textError={state.streetError}
                            />
                            <TextInput
                                inputRef={postalCodeInput}
                                onChangeText={(v) => setForm('postalCode', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        postalCodeError: '',
                                    });
                                }}
                                placeholder="PLZ"
                                success={!state.postalCodeError}
                                value={state.postalCode}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => cityInput.current.focus()}
                                textError={state.postalCodeError}
                            />
                            <TextInput
                                inputRef={cityInput}
                                onChangeText={(v) => setForm('city', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        cityError: '',
                                    });
                                }}
                                placeholder="Stadt"
                                success={!state.cityError}
                                value={state.city}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => countryInput.current.focus()}
                                textError={state.cityError}
                            />
                            <TextInput
                                inputRef={countryInput}
                                onChangeText={(v) => setForm('country', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        countryError: '',
                                    });
                                }}
                                placeholder="Land"
                                success={!state.countryError}
                                value={state.country}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => phonenumberInput.current.focus()}
                                textError={state.countryError}
                            />
                            <TextInput
                                inputRef={phonenumberInput}
                                onChangeText={(v) => setForm('phonenumber', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        phonenumberError: '',
                                    });
                                }}
                                placeholder="Telefonnummer"
                                success={!state.phonenumberError}
                                value={state.phonenumber}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => emailInput.current.focus()}
                                textError={state.phonenumberError}
                            />

                            <TextInput
                                autoComplete="email"
                                inputRef={emailInput}
                                onChangeText={(v) => setForm('email', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        emailError: '',
                                    });
                                }}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                placeholder="E-Mail"
                                name="email"
                                textContentType="emailAddress"
                                success={!state.emailError}
                                value={state.email}
                                onSubmitEditing={handleSubmit}
                            />
                        </View>
                    </ScrollView>
                    <View style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                        <View style={{ alignItems: 'center', paddingBottom: 20 }}>
                            <Button full loading={state.creating} onPress={handleSubmit} style={{ marginTop: 20, width: '50%', height: 40 }}>
                                {' '}
                                Speichern
                            </Button>
                        </View>
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
}

function mapState(state) {
    const { user } = state.users;
    const { creating } = state.clients.all;
    return { user, creating };
}

const actionCreators = {
    createClient: clientActions.create,
};

export default connect(mapState, actionCreators)(NewClient);
