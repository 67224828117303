import React, { useState, useEffect } from 'react';
import { View, Image, StyleSheet, ActivityIndicator } from 'react-native';

const AutoSizeImage = ({ uri }) => {
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        Image.getSize(
            uri,
            (width, height) => {
                setDimensions({ width, height });
                setLoading(false);
            },
            (error) => {
                console.error(`Couldn't get the image size: ${error.message}`);
                setLoading(false);
            }
        );
    }, [uri]);

    if (loading) {
        return (
            <View style={styles.loadingContainer}>
                <ActivityIndicator size="large" color="#0000ff" />
            </View>
        );
    }

    return (
        <img
            src={uri}
            style={{
                width: dimensions.width > dimensions.height ? '100%' : 'auto',
                height: dimensions.height >= dimensions.width ? `${(dimensions.height >= 800 ? 800 : dimensions.height) - 157}px` : 'auto',
                resizeMode: 'contain',
            }}
        />
    );
};

const styles = StyleSheet.create({
    loadingContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

export default AutoSizeImage;
