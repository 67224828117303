import React, { useCallback } from 'react';
import { ScrollView, View } from 'react-native';
import { BaseStyle, useTheme } from '@config';
import { Header, SafeAreaView, Icon } from '@components';
import { useDispatch, useSelector } from 'react-redux';
import { formatDate, getImageUrl } from '../../helpers/functions';
import { BaseColor } from '../../config/theme';
import { userActions } from '@actions';
import { useFocusEffect } from '@react-navigation/native';
import SettingsTag from '../SettingTag';
import Menu from '../../components/Menu';
import { ProfileDetail, Text } from '../../components';
import { userSelector } from '../../selectors';
import * as SplashScreen from 'expo-splash-screen';

export default function Profile({ navigation }) {
    const { colors } = useTheme();
    const dispatch = useDispatch();
    const user = useSelector(userSelector.user);
    const roles = user?.roles;

    useFocusEffect(
        useCallback(() => {
            dispatch(userActions.getUserAuth());
            return () => {};
        }, [])
    );

    if (!user) {
        return <View></View>;
    }
    const onLayoutRootView = useCallback(async () => {
        await SplashScreen.hideAsync();
    }, []);
    return (
        <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
            <Header
                title="Mein Konto"
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" color={colors.text} enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <ScrollView>
                    <View style={{ padding: 20 }}>
                        <ProfileDetail textSecond={'Aktiv seit ' + formatDate(user?.createdAt, 'de')} user={user} />
                    </View>
                    <SettingsTag navigation={navigation} isAdmin={roles?.indexOf(1) > -1} />
                </ScrollView>
                <View style={{ alignItems: 'center', paddingBottom: 15 }}>
                    <Text grayColor>Version: 1.0.0</Text>
                </View>
            </SafeAreaView>
        </View>
    );
}

const menuIcon = {
    width: 60,
    height: 60,
    borderRadius: 50,
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 20,
    alignItems: 'center',
    justifyContent: 'center',
};
