import React, { useState, useRef, useCallback } from 'react';
import { View, TouchableOpacity, KeyboardAvoidingView, Platform, ScrollView, StatusBar } from 'react-native';
import { connect, useSelector } from 'react-redux';
import { BaseStyle, useTheme } from '@config';
import { Header, SafeAreaView, Icon, Text, Button, TextInput, AlertComponent } from '@components';
import styles from './styles';
import { BaseColor } from '../../config/theme';
import { userActions } from '../../redux/actions';
import { useEffect } from 'react';
import { Images } from '../../config';
import { Image } from '../../components';
import { Linking } from 'react-native';
import { Vibration } from 'react-native';
import * as SplashScreen from 'expo-splash-screen';
import { noAccent } from '../../helpers/functions';
import * as NavigationBar from 'expo-navigation-bar';

function SignInScreen({ navigation, user, route, login, logging }) {
    const { colors } = useTheme();

    const [state, setState] = useState({
        firstname: '',
        lastname: '',
        password: '',
        submited: false,
        errorMessage: null,
        firstnameError: null,
        lastnameError: null,
        passwordError: null,
        condition: false,
    });

    const [passwordVisibility, setPasswordVisibility] = useState(false);

    const setForm = (name, value) => {
        setState((s) => ({ ...s, [name]: value }));
    };

    async function handleSubmit(e) {
        setState((s) => ({
            ...s,
            firstnameError: null,
            lastnameError: null,
            errorMessage: null,
            passwordError: null,
        }));

        if (!logging && loginValidator()) {
            const { firstname, lastname, password } = state;
            if (firstname && lastname && password) {
                setState((lState) => ({
                    ...lState,
                    emailError: null,
                    passwordError: null,
                    errorMessage: null,
                    submited: true,
                }));
                login({ firstname: firstname.trim().toLowerCase(), lastname: lastname.trim().toLowerCase(), password: password.trim() }, setState);
            }
        }
    }

    function loginValidator() {
        const passwordReg = /^[0-9a-zA-Z.!#$%&'+-_üäöß]{1,}$/;
        if (state.firstname.trim()?.length > 0 && state.lastname.trim()?.length > 0 && passwordReg.test(noAccent(state.password))) {
            setState((lState) => ({
                ...lState,
                firstnameError: null,
                lastnameError: null,
                passwordError: null,
                errorMessage: null,
            }));
            return true;
        }
        let newState = state;

        if (state.lastname == '') {
            newState = { ...newState, lastnameError: true };
        } else {
            newState = {
                ...newState,
                lastnameError: null,
                errorMessage: null,
            };
        }
        if (state.firstname == '') {
            newState = { ...newState, firstnameError: true };
        } else {
            newState = {
                ...newState,
                firstnameError: null,
                errorMessage: null,
            };
        }
        if (state.password == '') {
            newState = { ...newState, passwordError: true };
        } else if (!passwordReg.test(noAccent(state.password))) {
            newState = {
                ...newState,
                passwordError: 'Ungültiges Passwort',
            };
        } else {
            newState = {
                ...newState,
            };
        }
        setState(newState);
        Vibration.vibrate(10);

        return false;
    }
    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });
    useEffect(() => {
        if (user?.createdAt != null) {
            //navigation.navigate('Main');
        }
    }, [user?.createdAt]);

    useEffect(() => {
        if (Platform.OS === 'android') {
            StatusBar.setBarStyle('light-content', true);
            StatusBar.setBackgroundColor('#000');
            NavigationBar.setBackgroundColorAsync('#000');
            NavigationBar.setButtonStyleAsync('light');
        }
        return () => {
            if (Platform.OS === 'android') {
                StatusBar.setBarStyle('dark-content', true);
                StatusBar.setBackgroundColor('white');
                NavigationBar.setBackgroundColorAsync('white');
                NavigationBar.setButtonStyleAsync('dark');
            }
        };
    }, []);
    const lastnameInput = useRef(null);
    const passwordInput = useRef(null);

    const onLayoutRootView = useCallback(async () => {
        await SplashScreen.hideAsync();
    }, []);
    //    Alert.alert({ title: 'Einstellungen', type: 'warning', message: 'Unerwartete Fehler' });

    return (
        <View style={{ flex: 1, backgroundColor: '#000000' }} onLayout={onLayoutRootView}>
            <Header
                //title="Einloggen"
                // renderLeft={() => {
                //     return <Icon name="arrowleft" size={25} color={colors.text} type="ad" enableRTL={true} />;
                // }}
                // onPressLeft={() => {
                //     navigation.goBack();
                // }}
                style={{
                    backgroundColor: '#000000',
                }}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1 }}
                >
                    <ScrollView contentContainerStyle={{ alignItems: 'center' }}>
                        <View style={{ width: '100%', maxWidth: 500 }}>
                            <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                                <Image style={{ height: 107, width: 200 }} source={Images.logoB} />
                            </View>
                            <View style={styles.contain}>
                                <View>
                                    <TextInput
                                        onChangeText={(v) => setForm('firstname', v)}
                                        onFocus={() => {
                                            setState({
                                                ...state,
                                                firstnameError: '',
                                            });
                                        }}
                                        placeholder="Vorname *"
                                        success={!state.firstnameError}
                                        value={state.firstname}
                                        style={{ height: 50, marginTop: 20, width: '100%' }}
                                        name="name"
                                        textContentType="name"
                                        onSubmitEditing={() => lastnameInput.current.focus()}
                                        textError={state.firstnameError}
                                        iconLeft={<Icon name="user" type="ad" size={25} />}
                                    />
                                    <TextInput
                                        inputRef={lastnameInput}
                                        onChangeText={(v) => setForm('lastname', v)}
                                        onFocus={() => {
                                            setState({
                                                ...state,
                                                lastnameError: '',
                                            });
                                        }}
                                        placeholder="Nachname *"
                                        success={!state.lastnameError}
                                        value={state.lastname}
                                        style={{ height: 50, marginTop: 20 }}
                                        name="familyName"
                                        textContentType="familyName"
                                        onSubmitEditing={() => passwordInput.current.focus()}
                                        textError={state.lastnameError}
                                        iconLeft={<Icon name="user" type="ad" size={25} />}
                                    />
                                    <TextInput
                                        autoComplete="password"
                                        inputRef={passwordInput}
                                        onChangeText={(v) => setForm('password', v)}
                                        onFocus={() => {
                                            setState({
                                                ...state,
                                                passwordError: '',
                                            });
                                        }}
                                        style={{ marginTop: 20, height: 50, backgroundColor: BaseColor.whiteColor }}
                                        placeholder="Passwort *"
                                        secureTextEntry={!passwordVisibility}
                                        name="password"
                                        textContentType="password"
                                        success={!state.passwordError}
                                        value={state.password}
                                        icon={
                                            <TouchableOpacity
                                                style={{
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    paddingHorizontal: 5,
                                                    height: '100%',
                                                }}
                                                onPress={() => setPasswordVisibility((pw) => !pw)}
                                            >
                                                <Icon name={passwordVisibility ? 'eye' : 'eye-slash'} size={18} enableRTL={true} />
                                            </TouchableOpacity>
                                        }
                                        iconLeft={<Icon name="lock" type="ad" size={25} />}
                                        onSubmitEditing={handleSubmit}
                                    />
                                    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                                        <Button
                                            icon={<Icon name="login" type="ad" color="#ffffff" style={{ marginRight: 10 }} size={20} />}
                                            full
                                            loading={state.submited}
                                            onPress={handleSubmit}
                                            style={{ marginTop: 20, width: '70%', height: 40 }}
                                        >
                                            Einloggen
                                        </Button>
                                    </View>
                                    <View style={{ width: '100%', backgroundColor: BaseColor.whiteColor + '22', borderRadius: 30, top: 20 }}>
                                        {state.errorMessage && <AlertComponent message={state.errorMessage} type="error" />}
                                        {state.successMessage && <AlertComponent message={state.successMessage} type="success" />}
                                    </View>
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                    <View style={{ paddingBottom: 20 }}>
                        <Text whiteColor subhead style={{ textAlign: 'center' }}>
                            Haben Sie Probleme beim Einloggen?
                        </Text>
                        <TouchableOpacity onPress={() => Linking.openURL('mailto:webservices@gate.de')}>
                            <Text subhead primaryColor style={{ textAlign: 'center' }}>
                                Kontaktieren Sie uns
                            </Text>
                        </TouchableOpacity>
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
}
function mapState(state) {
    const { logging, user } = state?.users;
    return { logging, user };
}

const actionCreators = {
    login: userActions.login,
};

const SignIn = connect(mapState, actionCreators)(SignInScreen);
export default SignIn;
