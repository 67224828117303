import React, { useEffect, useRef, useState } from 'react';
import { View, SafeAreaView, KeyboardAvoidingView, TouchableOpacity } from 'react-native';
import { Icon, Text } from '@components';
import { connect } from 'react-redux';
import { BaseColor } from '../../config/theme';
import { AlertComponent, Button, Header, Image, TextInput } from '../../components';
import { BaseStyle } from '../../config';
import { Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import styles from './styles';
import Menu from '../../components/Menu';
import * as ImagePicker from 'expo-image-picker';
import { saveImg } from '../../helpers/apis';
import { showMessage } from 'react-native-flash-message';
import { compressImage } from '../../helpers/functions';
import CostumModal from '../../components/CostumModal';
import PostOptions from '../../components/PostOptions';
import { clientActions, userActions } from '../../redux/actions';

const roles = [
    {
        name: 'Projektleiter',
        value: 1,
    },
    {
        name: 'Kunde',
        value: 2,
    },
    {
        name: 'Außendienstmitarbeiter',
        value: 3,
    },
];
function UserNew({ navigation, createUser, getAllClient, allClient }) {
    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });

    const [state, setState] = useState({
        firstname: '',
        lastname: '',
        password: '',
        phonenumber: '',
        email: '',
        roles: [],
        clientId: null,
    });

    useEffect(() => {
        getAllClient();
    }, []);
    const setForm = (name, value) => {
        setState((s) => ({ ...s, [name]: value }));
    };

    function handleSubmit() {
        if (
            !(
                !state.creating &&
                state.firstname &&
                state.lastname &&
                state.password &&
                state.roles.length > 0 &&
                (state.roles[0]?.value != 2 || state.client)
            )
        ) {
            setState((s) => ({
                ...s,
                firstnameError: state.firstname ? '' : 'Vorname erforderlich',
                lastnameError: state.lastname ? '' : 'Nachname erforderlich',
                passwordError: state.password ? '' : 'Pawwort erforderlich',
                rolesError: state.roles.length > 0 ? '' : 'Bitte Rolle auswählen',
                clientError: state.roles[0]?.value != 2 || state.client ? '' : 'Bitte Kunde auswählen',
            }));
            return;
        }
        const { firstname, lastname, password, phonenumber, email } = state;
        setForm('creating', true);
        createUser(
            { firstname, lastname, password, phonenumber, email, roles: state.roles.map((r) => r.value), clientId: state.client?.id },
            () => {
                setForm('creating', false);
                navigation.goBack();
            },
            () => setForm('creating', false)
        );
    }
    const pickImage = async (camera) => {
        let result = '';
        const { status_roll } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
        await ImagePicker.requestCameraPermissionsAsync().then(async () => {
            result = await ImagePicker[camera ? 'launchCameraAsync' : 'launchImageLibraryAsync']({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                quality: 1,
                allowsMultipleSelection: false,
            });
        });

        if (!result.canceled) {
            setForm('image', { ...result.assets[0], uri: await compressImage(result.assets[0].uri) });
        }
    };

    const uploadImage = async (user) => {
        if (state.image == null) {
            setForm('creating', false);
            navigation.navigate('Home');
        } else
            await saveImg(
                [
                    {
                        content: state.image.uri,
                        name: user.logo.name,
                    },
                ],
                user.logo.path
            )
                .then((res) => {
                    setForm('creating', false);
                    showMessage({ message: 'Der Kunde wurde erfolgreich gespeichert!', icon: 'success' });
                    navigation.navigate('Home');
                })
                .catch((error) => {
                    console.error(error);
                    setForm('creating', false);
                    showMessage({
                        message: 'Ein unerwarteter Fehler ist aufgetreten! Überprüfen Sie Ihre Verbindung oder versuchen Sie es später erneut',
                        icon: 'warning',
                    });
                    //deletePosts(user?.id, [response.id]);
                    return error;
                });
    };
    const lastnameInput = useRef(null);
    const passwordInput = useRef(null);
    const phonenumberInput = useRef(null);
    const emailInput = useRef(null);

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <Header
                title="Benutzer hinzufügen"
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1 }}
                >
                    <ScrollView>
                        <View style={{ padding: 20, paddingBottom: 80 }}>
                            {/* <View style={{ width: 100 }}>
                                {state.image != null && (
                                    <View>
                                        <View
                                            style={{
                                                borderRadius: 50,
                                                borderWidth: 2,
                                                position: 'relative',
                                                borderStyle: 'solid',
                                                borderColor: BaseColor.grayTransparent,
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Image source={{ uri: state.image.uri }} style={{ width: 100, height: 100 }} />
                                        </View>
                                        <View
                                            style={{
                                                position: 'absolute',
                                                top: 3,
                                                right: 3,
                                            }}
                                        >
                                            <TouchableOpacity
                                                style={{
                                                    width: 30,
                                                    height: 30,
                                                    borderRadius: 50,
                                                    borderWidth: 2,
                                                    borderRadius: 20,
                                                    backgroundColor: BaseColor.whiteColor,
                                                    borderColor: BaseColor.grayTransparent,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                                onPress={() => setForm('image', null)}
                                            >
                                                <Icon name="delete" type="ad" size={15} enableRTL={true} />
                                            </TouchableOpacity>
                                        </View>
                                    </View>
                                )}
                                <View>
                                    <Menu
                                        visibile={state.image == null}
                                        activator={() => (
                                            <View
                                                style={{
                                                    borderStyle: 'solid',
                                                    borderColor: '#eee',
                                                    borderWidth: 2,
                                                    borderRadius: 50,
                                                    padding: 20,
                                                    backgroundColor: BaseColor.grayLight,
                                                    height: 100,
                                                    width: 100,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <Icon name="camera" size={30} type="ft" color={BaseColor.text} enableRTL={true} />
                                            </View>
                                        )}
                                        activatorStyle={{ paddingRight: 2, width: 100 }}
                                        actionItems={[
                                            {
                                                text: 'Foto machen',
                                                action: () => pickImage(true),
                                                leftIcon: () => (
                                                    <View
                                                        style={{
                                                            width: 45,
                                                            height: 45,
                                                            borderRadius: 15,
                                                            backgroundColor: BaseColor.grayLight,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Icon name="camera" type="ft" size={23} color={BaseColor.text} enableRTL={true} />
                                                    </View>
                                                ),
                                            },
                                            {
                                                text: 'Aus Galerie auswählen',
                                                action: () => pickImage(false),
                                                leftIcon: () => (
                                                    <View
                                                        style={{
                                                            width: 45,
                                                            height: 45,
                                                            borderRadius: 15,
                                                            backgroundColor: BaseColor.grayLight,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Icon name="picture" type="ad" solid size={23} color={BaseColor.text} enableRTL={true} />
                                                    </View>
                                                ),
                                            },
                                        ]}
                                    />
                                </View>
                            </View>
                            <Text semibold grayColor>
                                Logo
                            </Text> */}
                            <TextInput
                                onChangeText={(v) => setForm('firstname', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        firstnameError: '',
                                    });
                                }}
                                placeholder="Vorname *"
                                success={!state.firstnameError}
                                value={state.firstname}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => lastnameInput.current.focus()}
                                textError={state.firstnameError}
                            />
                            <TextInput
                                inputRef={lastnameInput}
                                onChangeText={(v) => setForm('lastname', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        lastnameError: '',
                                    });
                                }}
                                placeholder="Nachname *"
                                success={!state.lastnameError}
                                value={state.lastname}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => passwordInput.current.focus()}
                                textError={state.lastnameError}
                            />
                            <TextInput
                                inputRef={passwordInput}
                                onChangeText={(v) => setForm('password', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        passwordError: '',
                                    });
                                }}
                                placeholder="Passwort *"
                                success={!state.passwordError}
                                value={state.password}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => phonenumberInput.current.focus()}
                                textError={state.passwordError}
                            />
                            <TextInput
                                inputRef={phonenumberInput}
                                onChangeText={(v) => setForm('phonenumber', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        phonenumberError: '',
                                    });
                                }}
                                placeholder="Telefonnummer"
                                value={state.phonenumber}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                onSubmitEditing={() => emailInput.current.focus()}
                            />

                            <TextInput
                                autoComplete="email"
                                inputRef={emailInput}
                                onChangeText={(v) => setForm('email', v)}
                                onFocus={() => {
                                    setState({
                                        ...state,
                                        emailError: '',
                                    });
                                }}
                                style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                placeholder="E-Mail"
                                name="email"
                                textContentType="emailAddress"
                                success={!state.emailError}
                                value={state.email}
                                onSubmitEditing={handleSubmit}
                            />

                            <View style={{ marginTop: 15 }}>
                                <CostumModal
                                    title="Rolle auswählen"
                                    activator={
                                        <View
                                            style={[
                                                BaseStyle.textInput,
                                                {
                                                    backgroundColor: BaseColor.card,
                                                    height: 40,
                                                    borderRadius: 0,
                                                },
                                            ]}
                                        >
                                            <View>
                                                {state.roles.length > 0 ? (
                                                    <Text>{state.roles[0]?.name}</Text>
                                                ) : (
                                                    <Text style={{ color: state.rolesError ? 'red' : BaseColor.grayColor }}>Rolle auswählen *</Text>
                                                )}
                                            </View>
                                            <Icon name="chevron-right" size={15} color={BaseColor.gray} style={{ marginRight: 5 }} enableRTL={true} />
                                        </View>
                                    }
                                    renderComponent={(onApply) => (
                                        <PostOptions
                                            value={state.roles[0]}
                                            options={roles}
                                            onChange={(v) => {
                                                setForm('roles', [v]);
                                                if (v.value != 2) setForm('client', null);
                                                onApply();
                                            }}
                                        />
                                    )}
                                />
                            </View>
                            {state.roles[0]?.value == 2 && (
                                <View style={{ marginTop: 15 }}>
                                    <CostumModal
                                        title="Kunde auswählen"
                                        activator={
                                            <View
                                                style={[
                                                    BaseStyle.textInput,
                                                    {
                                                        backgroundColor: BaseColor.card,
                                                        height: 40,
                                                        borderRadius: 0,
                                                    },
                                                ]}
                                            >
                                                <View>
                                                    {state.client ? (
                                                        <Text>{state.client.name}</Text>
                                                    ) : (
                                                        <Text style={{ color: state.clientError ? 'red' : BaseColor.grayColor }}>
                                                            Firma Auswählen *
                                                        </Text>
                                                    )}
                                                </View>
                                                <Icon
                                                    name="chevron-right"
                                                    size={15}
                                                    color={BaseColor.gray}
                                                    style={{ marginRight: 5 }}
                                                    enableRTL={true}
                                                />
                                            </View>
                                        }
                                        renderComponent={(onApply) => (
                                            <PostOptions
                                                value={state.client}
                                                options={allClient.rows}
                                                onChange={(v) => {
                                                    setForm('client', v);
                                                    onApply();
                                                }}
                                            />
                                        )}
                                    />
                                </View>
                            )}
                        </View>
                    </ScrollView>
                    <View style={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }}>
                        <View style={{ alignItems: 'center', paddingBottom: 20 }}>
                            <Button
                                full
                                loading={state.creating}
                                onPress={handleSubmit}
                                style={{ marginTop: 15, width: '50%', height: 40 }}
                            >
                                {' '}
                                Speichern
                            </Button>
                        </View>
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
}

function mapState(state) {
    const { user } = state.users;
    const { all } = state.clients;
    const { creating } = state.users.all;
    return { user, creating, allClient: all };
}

const actionCreators = {
    createUser: userActions.register,
    getAllClient: clientActions.getAll,
};

export default connect(mapState, actionCreators)(UserNew);
