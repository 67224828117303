import { io } from 'socket.io-client';
import { authHeader } from './auth-header';
import { proxy } from './proxy';

export { saveImg, listFiles, exportWithFiles };

async function saveImg(fileInput, filePath) {
    const formData = new FormData();

    for (var i = 0; i < fileInput.length; i++) {
        formData.append(`files`, fileInput[i].content, fileInput[i].name);
    }
    formData.append(`filePath`, filePath);

    let result = await fetch(`${proxy}/files/upload`, {
        method: 'POST',
        headers: {
            ...authHeader(),
            Accept: 'application/json',
        },
        body: formData,
    });
    return result;
}

async function listFiles(from) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify({ from }),
    };
    let result = await fetch(`${proxy}/files/list`, requestOptions);
    return result;
}

async function exportWithFiles(id, actionName, setState, alertExport) {

    const socket = io(proxy);

    socket.on('progress-' + id, ({ type, progress }) => {
        setState((s) => ({ ...s, [type]: progress }));
    });

    setState((s) => ({ ...s, exporting: true, progress: 0 }));
    const requestOptions = {
        method: 'Get',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };
    try {
        const response = await fetch(`${proxy}/exportWithFiles/${id}`, requestOptions);
        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `${actionName} ${new Date().toLocaleDateString()}.zip`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            alertExport('Exportdatei erfolgreich heruntergeladen!');
            setState((s) => ({ ...s, exporting: false, exportSuccessfull: true }));
            socket.disconnect();
        } else {
            alertExport('Datei konnte nicht heruntergeladen werden');
            setState((s) => ({ ...s, exporting: false, exportSuccessfull: false }));
            socket.disconnect();
        }
    } catch (error) {
        console.error('Error:', error);
        alertExport('Datei konnte nicht heruntergeladen werden');
        setState((s) => ({ ...s, exporting: false, exportSuccessfull: false }));
        socket.disconnect();
    }
}
