import React, { useCallback, useState } from 'react';
import { View, Platform, KeyboardAvoidingView, ScrollView, RefreshControl, Dimensions } from 'react-native';
import { useTheme } from '@config';
import { SafeAreaView, Icon } from '@components';
import { connect } from 'react-redux';
import { BaseColor, BaseStyle } from '../../config';
import { useFocusEffect } from '@react-navigation/native';
import { Button, Header, Image, Text } from '../../components';
import * as SplashScreen from 'expo-splash-screen';
import { PieChart, ProgressChart } from 'react-native-chart-kit';
import { actionActions, userActions } from '../../redux/actions';
import { AnimatedCircularProgress } from 'react-native-circular-progress';
import { deviceDimentions } from '../../utils';
import { compareDate, formatDate, formatDate2, getImageUrl } from '../../helpers/functions';
import Menu from '../../components/Menu';

function Statistics({ getUserAuth, deleteAction, navigation, route, role, user }) {
    const { colors } = useTheme();
    const [state, setState] = useState({ loading: true });

    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });
    useFocusEffect(
        useCallback(() => {
            getUserAuth();
            onRefresh();
        }, [])
    );
    const onRefresh = () => {
        userActions.getStatistics(
            user.id,
            () => setState((s) => ({ loading: true })),
            (stats) => setState((s) => ({ stats, selectedClients: s.selectedClients ?? stats.clients?.list, loading: false })),
            (err) => setState({ err, loading: false })
        );
    };
    const onLayoutRootView = useCallback(async () => {
        await SplashScreen.hideAsync();
    }, []);
    const personalData = [
        {
            name: 'Projektle',
            population: state.stats?.users?.pl,
            color: BaseColor.primary,
            legendFontColor: '#000',
            legendFontSize: 11,
        },
        {
            name: 'Kunde',
            population: state.stats?.users?.client,
            color: BaseColor.blueColor,
            legendFontColor: '#000',
            legendFontSize: 11,
        },
        {
            name: 'Außendie...',
            population: state.stats?.users?.adm,
            color: BaseColor.greenDark,
            legendFontColor: '#000',
            legendFontSize: 11,
        },
    ];

    return (
        <View style={{ flex: 1, backgroundColor: BaseColor.grayLight }} onLayout={onLayoutRootView}>
            <Header
                title={'Statistiken'}
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
                //renderRight={role == 1 ? adminAction : undefined}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1 }}
                >
                    <ScrollView
                        contentContainerStyle={{ padding: 15, paddingBottom: 80 }}
                        refreshControl={
                            <RefreshControl colors={[colors.primary]} tintColor={colors.primary} refreshing={state.loading} onRefresh={onRefresh} />
                        }
                    >
                        <div style={{ fontFamily: 'sans-serif' }}>
                            <View style={{ paddingVertical: 10, flexDirection: 'row' }}>
                                <View style={{ flex: 1, alignItems: 'flex-end' }}>
                                    <View>
                                        <Menu
                                            activator={() => (
                                                <View
                                                    style={[
                                                        {
                                                            backgroundColor: BaseColor.whiteColor,
                                                            borderRadius: 20,
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                            padding: 10,
                                                            flexDirection: 'row',
                                                        },
                                                    ]}
                                                >
                                                    <Text>{state.selectedClients?.length == 1 ? state.selectedClients[0]?.name : 'Alle Kunden'}</Text>
                                                    <Icon
                                                        name="chevron-down"
                                                        size={20}
                                                        color={BaseColor.primary}
                                                        enableRTL={true}
                                                        style={{ marginLeft: 10 }}
                                                    />
                                                </View>
                                            )}
                                            actionItems={[
                                                state?.stats?.clients?.list?.length > 1
                                                    ? {
                                                          text: 'Alle Kunden',
                                                          rightIcon: () => (
                                                              <Icon
                                                                  name="check"
                                                                  size={20}
                                                                  color={
                                                                      state.selectedClients?.length == 1 ? BaseColor.whiteColor : BaseColor.primary
                                                                  }
                                                                  style={{ marginLeft: 20 }}
                                                              />
                                                          ),
                                                          action: () => {
                                                              setState((s) => ({ ...s, selectedClients: state.stats.clients?.list }));
                                                          },
                                                      }
                                                    : {},
                                                ...(state?.stats?.clients?.list ? state?.stats?.clients?.list : []).map((client) => ({
                                                    text: client.name,
                                                    leftIcon: () => (
                                                        <View
                                                            style={{
                                                                backgroundColor: BaseColor.grayLight,
                                                                height: 45,
                                                                width: 45,
                                                                borderRadius: 30,
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                overflow: 'hidden',
                                                            }}
                                                        >
                                                            {client.logo ? (
                                                                <Image
                                                                    source={{ uri: getImageUrl(`${client.logo.path}/${client.logo.name}`) }}
                                                                    style={{ width: 45, height: 45 }}
                                                                />
                                                            ) : (
                                                                <Text bold>{client.name.slice(0, 1)}</Text>
                                                            )}
                                                        </View>
                                                    ),
                                                    rightIcon: () => (
                                                        <Icon
                                                            name="check"
                                                            size={20}
                                                            color={
                                                                state.selectedClients?.length == 1 && state.selectedClients[0]?.name == client.name
                                                                    ? BaseColor.primary
                                                                    : BaseColor.whiteColor
                                                            }
                                                            style={{ marginLeft: 20 }}
                                                        />
                                                    ),
                                                    action: () => setState((s) => ({ ...s, selectedClients: [client] })),
                                                })),
                                            ]}
                                        />
                                    </View>
                                </View>
                            </View>

                            {state.stats && (
                                <View>
                                    <View>
                                        <View style={{ paddingVertical: 10, flexDirection: 'row' }}>
                                            <Text headline bold>
                                                Laufende Aktionen
                                            </Text>
                                            <Text>
                                                {' '}
                                                (insg. {state.selectedClients?.reduce((prev, curr) => prev + curr.actions?.running?.length, 0)})
                                            </Text>
                                        </View>
                                        {state.selectedClients?.map((client, key1) =>
                                            client.actions.running.map((action, key2) => (
                                                <View
                                                    style={{
                                                        padding: 20,
                                                        borderRadius: 15,
                                                        marginBottom: 15,
                                                        backgroundColor: BaseColor.whiteColor,
                                                        flexDirection: deviceDimentions.width > 500 ? 'row' : 'column',
                                                    }}
                                                    key={`laufende${key1}-${key2}`}
                                                >
                                                    <View
                                                        style={{
                                                            flex: deviceDimentions.width > 500 ? 2 : 1,
                                                        }}
                                                    >
                                                        <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                                                            <View>
                                                                <View style={{ flexDirection: 'row' }}>
                                                                    <Text>{action?.name}</Text>
                                                                    <View
                                                                        style={{
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            paddingHorizontal: 5,
                                                                        }}
                                                                    >
                                                                        <View style={{ width: 3, height: 3, backgroundColor: 'black' }} />
                                                                    </View>
                                                                    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                                        <View
                                                                            style={{
                                                                                flexDirection: 'row',
                                                                            }}
                                                                        >
                                                                            {compareDate(currentDate, action?.endDate) >= 0 ? (
                                                                                <Text caption1 errorColor>
                                                                                    Vorbei
                                                                                </Text>
                                                                            ) : compareDate(action?.startDate) >= 0 ? (
                                                                                <Text caption1 style={{ color: BaseColor.greenColor }}>
                                                                                    Läuft gerade
                                                                                </Text>
                                                                            ) : (
                                                                                <Text caption1 primaryColor>
                                                                                    Beginnt {formatDate(action?.startDate)}
                                                                                </Text>
                                                                            )}
                                                                        </View>
                                                                    </View>
                                                                </View>
                                                                <View style={{ alignItems: 'flex-start', paddingTop: 5 }}>
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View
                                                                            style={{
                                                                                paddingHorizontal: 10,
                                                                                paddingVertical: 5,
                                                                                borderRadius: 20,
                                                                                backgroundColor: BaseColor.grayLight,
                                                                            }}
                                                                        >
                                                                            <Text caption2>
                                                                                {action?.startDate && formatDate2(action?.startDate)} -{' '}
                                                                                {action?.startDate && formatDate2(action?.endDate)}
                                                                            </Text>
                                                                        </View>
                                                                    </View>
                                                                </View>
                                                            </View>
                                                        </View>
                                                        <View>
                                                            <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                                                                <View style={{ alignItems: 'center', flex: 1 }}>
                                                                    <AnimatedCircularProgress
                                                                        size={90}
                                                                        width={15}
                                                                        fill={(
                                                                            (action.stores.confirmed /
                                                                                (action?.stores?.count > 0 ? action.stores.count : 1)) *
                                                                            100
                                                                        ).toFixed(1)}
                                                                        tintColor={BaseColor.greenDark}
                                                                        backgroundColor={BaseColor.greenDark + '22'}
                                                                        style={{}}
                                                                    >
                                                                        {(fill) => (
                                                                            <View style={{ alignItems: 'center' }}>
                                                                                <Text bold>
                                                                                    {(
                                                                                        (action.stores.confirmed /
                                                                                            (action?.stores?.count > 0 ? action.stores.count : 1)) *
                                                                                        100
                                                                                    ).toFixed(1)}
                                                                                    %
                                                                                </Text>
                                                                                <Text caption2>Bestätigt</Text>
                                                                            </View>
                                                                        )}
                                                                    </AnimatedCircularProgress>
                                                                </View>
                                                                <View style={{ alignItems: 'center', flex: 1 }}>
                                                                    <AnimatedCircularProgress
                                                                        size={90}
                                                                        width={15}
                                                                        fill={(
                                                                            (action.stores.visitedUnconfirmed /
                                                                                (action?.stores?.count > 0 ? action.stores.count : 1)) *
                                                                            100
                                                                        ).toFixed(1)}
                                                                        tintColor={BaseColor.yellowColor}
                                                                        backgroundColor={BaseColor.yellowColor + '33'}
                                                                    >
                                                                        {(fill) => (
                                                                            <View style={{ alignItems: 'center' }}>
                                                                                <Text bold>
                                                                                    {(
                                                                                        (action.stores.visitedUnconfirmed /
                                                                                            (action?.stores?.count > 0 ? action.stores.count : 1)) *
                                                                                        100
                                                                                    ).toFixed(1)}
                                                                                    %
                                                                                </Text>
                                                                                <Text caption2>Besucht</Text>
                                                                            </View>
                                                                        )}
                                                                    </AnimatedCircularProgress>
                                                                </View>
                                                                <View style={{ alignItems: 'center', flex: 1 }}>
                                                                    <AnimatedCircularProgress
                                                                        size={90}
                                                                        width={15}
                                                                        fill={(
                                                                            (action.stores.unvisited /
                                                                                (action?.stores?.count > 0 ? action.stores.count : 1)) *
                                                                            100
                                                                        ).toFixed(1)}
                                                                        tintColor={BaseColor.errorColor}
                                                                        backgroundColor={BaseColor.errorColor + '33'}
                                                                    >
                                                                        {(fill) => (
                                                                            <View style={{ alignItems: 'center' }}>
                                                                                <Text bold>
                                                                                    {(
                                                                                        (action.stores.unvisited /
                                                                                            (action?.stores?.count > 0 ? action.stores.count : 1)) *
                                                                                        100
                                                                                    ).toFixed(1)}
                                                                                    %
                                                                                </Text>
                                                                                <Text caption2>Unbesucht</Text>
                                                                            </View>
                                                                        )}
                                                                    </AnimatedCircularProgress>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                            justifyContent: 'center',
                                                            flexDirection: deviceDimentions.width > 500 ? 'column' : 'row',
                                                            paddingLeft: deviceDimentions.width > 500 ? 20 : 0,
                                                            paddingTop: deviceDimentions.width > 500 ? 0 : 5,
                                                        }}
                                                    >
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: deviceDimentions.width > 500 ? 'flex-start' : 'center',
                                                                flex: 1,
                                                            }}
                                                        >
                                                            {deviceDimentions.width > 500 && (
                                                                <View
                                                                    style={{
                                                                        width: 15,
                                                                        height: 15,
                                                                        backgroundColor: BaseColor.greenDark,
                                                                        borderRadius: 20,
                                                                        marginRight: 10,
                                                                    }}
                                                                />
                                                            )}
                                                            <Text caption1>
                                                                {action.stores.confirmed}
                                                                {deviceDimentions.width > 500 && ' Bestätigt'}
                                                            </Text>
                                                        </View>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: deviceDimentions.width > 500 ? 'flex-start' : 'center',
                                                                flex: 1,
                                                            }}
                                                        >
                                                            {deviceDimentions.width > 500 && (
                                                                <View
                                                                    style={{
                                                                        width: 15,
                                                                        height: 15,
                                                                        backgroundColor: BaseColor.yellowColor,
                                                                        borderRadius: 20,
                                                                        marginRight: 10,
                                                                    }}
                                                                />
                                                            )}
                                                            <Text caption1>
                                                                {action.stores.visitedUnconfirmed}
                                                                {deviceDimentions.width > 500 && ' Besucht'}
                                                            </Text>
                                                        </View>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: deviceDimentions.width > 500 ? 'flex-start' : 'center',
                                                                flex: 1,
                                                            }}
                                                        >
                                                            {deviceDimentions.width > 500 && (
                                                                <View
                                                                    style={{
                                                                        width: 15,
                                                                        height: 15,
                                                                        backgroundColor: BaseColor.errorColor,
                                                                        borderRadius: 20,
                                                                        marginRight: 10,
                                                                    }}
                                                                />
                                                            )}
                                                            <Text caption1>
                                                                {action.stores.unvisited}
                                                                {deviceDimentions.width > 500 && ' Unbesucht'}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            ))
                                        )}
                                    </View>

                                    <View>
                                        <View style={{ paddingVertical: 10, paddingTop: 15, flexDirection: 'row' }}>
                                            <Text headline bold>
                                                Abgelaufende Aktionen
                                            </Text>
                                            <Text>
                                                {' '}
                                                (insg. {state.selectedClients?.reduce((prev, curr) => prev + curr.actions?.ended?.length, 0)})
                                            </Text>
                                        </View>
                                        {state.selectedClients?.map((client, key1) =>
                                            client.actions.ended.map((action, key2) => (
                                                <View
                                                    style={{
                                                        padding: 20,
                                                        borderRadius: 15,
                                                        marginBottom: 15,
                                                        backgroundColor: BaseColor.whiteColor,
                                                        flexDirection: deviceDimentions.width > 500 ? 'row' : 'column',
                                                    }}
                                                    key={`abgelaufende${key1}-${key2}`}
                                                >
                                                    <View
                                                        style={{
                                                            flex: deviceDimentions.width > 500 ? 2 : 1,
                                                        }}
                                                    >
                                                        <View style={{ flexDirection: 'row', paddingBottom: 5 }}>
                                                            <View>
                                                                <View style={{ flexDirection: 'row' }}>
                                                                    <Text>{action?.name}</Text>
                                                                    <View
                                                                        style={{
                                                                            alignItems: 'center',
                                                                            justifyContent: 'center',
                                                                            paddingHorizontal: 5,
                                                                        }}
                                                                    >
                                                                        <View style={{ width: 3, height: 3, backgroundColor: 'black' }} />
                                                                    </View>
                                                                    <View style={{ alignItems: 'center', justifyContent: 'center' }}>
                                                                        <View
                                                                            style={{
                                                                                flexDirection: 'row',
                                                                            }}
                                                                        >
                                                                            {compareDate(currentDate, action?.endDate) >= 0 ? (
                                                                                <Text caption1 errorColor>
                                                                                    Vorbei
                                                                                </Text>
                                                                            ) : compareDate(action?.startDate) >= 0 ? (
                                                                                <Text caption1 style={{ color: BaseColor.greenColor }}>
                                                                                    Läuft gerade
                                                                                </Text>
                                                                            ) : (
                                                                                <Text caption1 primaryColor>
                                                                                    Beginnt {formatDate(action?.startDate)}
                                                                                </Text>
                                                                            )}
                                                                        </View>
                                                                    </View>
                                                                </View>
                                                                <View style={{ alignItems: 'flex-start', paddingTop: 5 }}>
                                                                    <View style={{ flexDirection: 'row' }}>
                                                                        <View
                                                                            style={{
                                                                                paddingHorizontal: 10,
                                                                                paddingVertical: 5,
                                                                                borderRadius: 20,
                                                                                backgroundColor: BaseColor.grayLight,
                                                                            }}
                                                                        >
                                                                            <Text caption2>
                                                                                {action?.startDate && formatDate2(action?.startDate)} -{' '}
                                                                                {action?.startDate && formatDate2(action?.endDate)}
                                                                            </Text>
                                                                        </View>
                                                                    </View>
                                                                </View>
                                                            </View>
                                                        </View>
                                                        <View>
                                                            <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                                                                <View style={{ alignItems: 'center', flex: 1 }}>
                                                                    <AnimatedCircularProgress
                                                                        size={90}
                                                                        width={15}
                                                                        fill={(
                                                                            (action.stores.confirmed /
                                                                                (action?.stores?.count > 0 ? action.stores.count : 1)) *
                                                                            100
                                                                        ).toFixed(1)}
                                                                        tintColor={BaseColor.greenDark}
                                                                        backgroundColor={BaseColor.greenDark + '22'}
                                                                        style={{}}
                                                                    >
                                                                        {(fill) => (
                                                                            <View style={{ alignItems: 'center' }}>
                                                                                <Text bold>
                                                                                    {(
                                                                                        (action.stores.confirmed /
                                                                                            (action?.stores?.count > 0 ? action.stores.count : 1)) *
                                                                                        100
                                                                                    ).toFixed(1)}
                                                                                    %
                                                                                </Text>
                                                                                <Text caption2>Bestätigt</Text>
                                                                            </View>
                                                                        )}
                                                                    </AnimatedCircularProgress>
                                                                </View>
                                                                <View style={{ alignItems: 'center', flex: 1 }}>
                                                                    <AnimatedCircularProgress
                                                                        size={90}
                                                                        width={15}
                                                                        fill={(
                                                                            (action.stores.visitedUnconfirmed /
                                                                                (action?.stores?.count > 0 ? action.stores.count : 1)) *
                                                                            100
                                                                        ).toFixed(1)}
                                                                        tintColor={BaseColor.yellowColor}
                                                                        backgroundColor={BaseColor.yellowColor + '33'}
                                                                    >
                                                                        {(fill) => (
                                                                            <View style={{ alignItems: 'center' }}>
                                                                                <Text bold>
                                                                                    {(
                                                                                        (action.stores.visitedUnconfirmed /
                                                                                            (action?.stores?.count > 0 ? action.stores.count : 1)) *
                                                                                        100
                                                                                    ).toFixed(1)}
                                                                                    %
                                                                                </Text>
                                                                                <Text caption2>Besucht</Text>
                                                                            </View>
                                                                        )}
                                                                    </AnimatedCircularProgress>
                                                                </View>
                                                                <View style={{ alignItems: 'center', flex: 1 }}>
                                                                    <AnimatedCircularProgress
                                                                        size={90}
                                                                        width={15}
                                                                        fill={(
                                                                            (action.stores.unvisited /
                                                                                (action?.stores?.count > 0 ? action.stores.count : 1)) *
                                                                            100
                                                                        ).toFixed(1)}
                                                                        tintColor={BaseColor.errorColor}
                                                                        backgroundColor={BaseColor.errorColor + '33'}
                                                                    >
                                                                        {(fill) => (
                                                                            <View style={{ alignItems: 'center' }}>
                                                                                <Text bold>
                                                                                    {(
                                                                                        (action.stores.unvisited /
                                                                                            (action?.stores?.count > 0 ? action.stores.count : 1)) *
                                                                                        100
                                                                                    ).toFixed(1)}
                                                                                    %
                                                                                </Text>
                                                                                <Text caption2>Unbesucht</Text>
                                                                            </View>
                                                                        )}
                                                                    </AnimatedCircularProgress>
                                                                </View>
                                                            </View>
                                                        </View>
                                                    </View>
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                            justifyContent: 'center',
                                                            flexDirection: deviceDimentions.width > 500 ? 'column' : 'row',
                                                            paddingLeft: deviceDimentions.width > 500 ? 20 : 0,
                                                            paddingTop: deviceDimentions.width > 500 ? 0 : 5,
                                                        }}
                                                    >
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: deviceDimentions.width > 500 ? 'flex-start' : 'center',
                                                                flex: 1,
                                                            }}
                                                        >
                                                            {deviceDimentions.width > 500 && (
                                                                <View
                                                                    style={{
                                                                        width: 15,
                                                                        height: 15,
                                                                        backgroundColor: BaseColor.greenDark,
                                                                        borderRadius: 20,
                                                                        marginRight: 10,
                                                                    }}
                                                                />
                                                            )}
                                                            <Text caption1>
                                                                {action.stores.confirmed}
                                                                {deviceDimentions.width > 500 && ' Bestätigt'}
                                                            </Text>
                                                        </View>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: deviceDimentions.width > 500 ? 'flex-start' : 'center',
                                                                flex: 1,
                                                            }}
                                                        >
                                                            {deviceDimentions.width > 500 && (
                                                                <View
                                                                    style={{
                                                                        width: 15,
                                                                        height: 15,
                                                                        backgroundColor: BaseColor.yellowColor,
                                                                        borderRadius: 20,
                                                                        marginRight: 10,
                                                                    }}
                                                                />
                                                            )}
                                                            <Text caption1>
                                                                {action.stores.visitedUnconfirmed}
                                                                {deviceDimentions.width > 500 && ' Besucht'}
                                                            </Text>
                                                        </View>
                                                        <View
                                                            style={{
                                                                flexDirection: 'row',
                                                                alignItems: 'center',
                                                                justifyContent: deviceDimentions.width > 500 ? 'flex-start' : 'center',
                                                                flex: 1,
                                                            }}
                                                        >
                                                            {deviceDimentions.width > 500 && (
                                                                <View
                                                                    style={{
                                                                        width: 15,
                                                                        height: 15,
                                                                        backgroundColor: BaseColor.errorColor,
                                                                        borderRadius: 20,
                                                                        marginRight: 10,
                                                                    }}
                                                                />
                                                            )}
                                                            <Text caption1>
                                                                {action.stores.unvisited}
                                                                {deviceDimentions.width > 500 && ' Unbesucht'}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            ))
                                        )}
                                    </View>
                                    {/* <View>
                                        <View style={{ paddingTop: 15, paddingBottom: 10, flexDirection: 'row' }}>
                                            <Text headline bold>
                                                Alle Märkte
                                            </Text>
                                            <Text> (insg. {state.stats?.stores.total})</Text>
                                        </View>

                                        <PieChart
                                            data={storeData}
                                            width={Math.min(800, deviceDimentions.width) - 30}
                                            height={220}
                                            chartConfig={chartConfig}
                                            accessor={'population'}
                                            backgroundColor={'transparent'}
                                            paddingLeft={'10'}
                                            style={{ backgroundColor: BaseColor.whiteColor, borderRadius: 16, padding: 10 }}
                                            center={[0, -10]}
                                            absolute
                                        />
                                    </View>
                                    <View>
                                        <View style={{ paddingTop: 15, paddingBottom: 10, flexDirection: 'row' }}>
                                            <Text headline bold>
                                                Aktionen
                                            </Text>
                                            <Text> (insg. {state.stats?.actions.total})</Text>
                                        </View>
                                        <PieChart
                                            data={actionData}
                                            width={Math.min(800, deviceDimentions.width) - 30}
                                            height={220}
                                            chartConfig={chartConfig}
                                            accessor={'population'}
                                            backgroundColor={'transparent'}
                                            paddingLeft={'10'}
                                            style={{ backgroundColor: BaseColor.whiteColor, borderRadius: 16, padding: 10 }}
                                            center={[0, -10]}
                                            absolute
                                        />
                                    </View> */}
                                    {state.stats?.users.total && (
                                        <View>
                                            <View style={{ paddingTop: 15, paddingBottom: 10, flexDirection: 'row' }}>
                                                <Text headline bold>
                                                    Personal
                                                </Text>
                                                <Text> (insg. {state.stats?.users.total})</Text>
                                            </View>
                                            <PieChart
                                                data={personalData}
                                                width={Math.min(800, deviceDimentions.width) - 30}
                                                height={220}
                                                chartConfig={chartConfig}
                                                accessor={'population'}
                                                backgroundColor={'transparent'}
                                                paddingLeft={'10'}
                                                style={{ backgroundColor: BaseColor.whiteColor, borderRadius: 16, padding: 10 }}
                                                center={[0, -10]}
                                                absolute
                                            />
                                        </View>
                                    )}
                                </View>
                            )}
                        </div>
                    </ScrollView>

                    <View style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                        <View style={{ padding: 15, alignItems: 'center', justifyContent: 'center' }}>
                            <Button
                                style={{ height: 40, backgroundColor: 'white' }}
                                textColor={'black'}
                                icon={<Icon name="refresh" size={15} type="fa" color={BaseColor.primary} />}
                                onPress={onRefresh}
                            >
                                {' '}
                                Werte neuladen
                            </Button>
                        </View>
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
}
const graphStyle = { borderRadius: 16, marginBottom: 20 };
const chartConfig = {
    backgroundGradientFrom: BaseColor.whiteColor,
    backgroundGradientFromOpacity: 0.8,
    backgroundGradientTo: BaseColor.whiteColor,
    backgroundGradientToOpacity: 1,
    color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
    strokeWidth: 2, // optional, default 3
    barPercentage: 0.5,
    useShadowColorFromDataset: false, // optional
};
const data = [
    {
        name: 'Projektle',
        population: 5,
        color: BaseColor.primary,
        legendFontColor: '#000',
        legendFontSize: 11,
    },
    {
        name: 'Kunde',
        population: 3,
        color: BaseColor.blueColor,
        legendFontColor: '#000',
        legendFontSize: 11,
    },
    {
        name: 'Außendie...',
        population: 20,
        color: BaseColor.greenDark,
        legendFontColor: '#000',
        legendFontSize: 11,
    },
];
const data1 = {
    labels: ['Test1', 'Test2', 'Test3'],
    legend: ['L1', 'L2', 'L3', 'L4'],
    data: [
        [60, 60, 60, 60],
        [30, 30, 50, 60],
        [10, 30, 50, 60],
    ],
    barColors: ['#dfe4ea', '#ced6e0', '#a4b0be', '#888'],
};
function mapState(state) {
    const { user } = state.users;
    return { role: user?.roles[0], user };
}

const storeCreators = {
    getUserAuth: userActions.getUserAuth,
    getSingleAction: actionActions.getSingle,
    deleteAction: actionActions.delete,
};

export default connect(mapState, storeCreators)(Statistics);
