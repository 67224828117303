import React from 'react';
import { View, SafeAreaView, KeyboardAvoidingView } from 'react-native';
import { Icon, Text } from '@components';
import { connect } from 'react-redux';
import { BaseColor } from '../../config/theme';
import { Header } from '../../components';
import { BaseStyle } from '../../config';
import { Platform } from 'react-native';

function NewAction({ navigation }) {
    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <Header
                title="Aktion anlegen"
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1 }}
                >
                    <View style={{ backgroundColor: BaseColor.grayLight }}>
                        <Text grayColor style={{ textAlign: 'center' }}>
                            Aktion anlegen
                        </Text>
                    </View>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
}

function mapState(state) {
    const { user } = state.users;
    return { user };
}

const actionCreators = {};

export default connect(mapState, actionCreators)(NewAction);
