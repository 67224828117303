import { StyleSheet } from 'react-native';
import { BaseColor } from '../../config';

export default StyleSheet.create({
    contentHeader: {
        paddingHorizontal: 20,
        paddingVertical: 8,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    contentSearch: {
        marginVertical: 8,
        paddingHorizontal: 20,
    },
    lineForm: {
        width: 1,
        height: '100%',
        margin: 10,
    },
    categoryContent: {
        height: 100,
        borderRadius: 15,
        alignItems: 'center',
        justifyContent: 'center',
        borderStyle: 'solid',
        borderColor: '#eee',
        borderWidth: 0.5,
    },
    avatarContent: {
        width: 36,
        height: 36,
    },
    avatar: {
        width: 36,
        height: 36,
        borderRadius: 18,
    },
    doc: {
        width: 10,
        height: 10,
        borderRadius: 8,
        borderWidth: 1,
        position: 'absolute',
        top: 0,
        right: 0,
    },
    badge: {
        minWidth: 18,
        height: 18,
        paddingHorizontal: 3,
        borderRadius: 15,
        borderWidth: 1,
        position: 'absolute',
        backgroundColor: BaseColor.errorColor,
        top: 0,
        right: 0,
        justifyContent: 'center',
        alignItems: 'center',
    },
    menuIcon: {
        width: 50,
        height: 50,
        position: 'absolute',
        bottom: 15,
        right: 15,
        borderRadius: 20,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
