import React, { useCallback, useState } from 'react';
import { View, ScrollView, RefreshControl, Linking, Platform, KeyboardAvoidingView, FlatList } from 'react-native';
import { useTheme, Images } from '@config';
import { SafeAreaView, Icon, Text, Image } from '@components';
import styles from './styles';
import { connect } from 'react-redux';
import { BaseColor, BaseStyle } from '../../config';
import { useFocusEffect } from '@react-navigation/native';
import { Button, Header } from '../../components';
import * as SplashScreen from 'expo-splash-screen';
import { actionActions, clientActions, userActions } from '../../redux/actions';
import ActionListItem from '../../components/ActionListItem';
import { TouchableOpacity } from 'react-native';
import { getImageUrl } from '../../helpers/functions';
import Menu from '../../components/Menu';
import { Alert } from 'react-native';
import { DeviceType } from 'expo-device';

function ActionList({ getUserAuth, deleteClient, getSingleClient, navigation, route, role, user, device }) {
    const { colors } = useTheme();
    const [state, setState] = useState({ all: [], loading: true, client: route.params.client });
    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });
    useFocusEffect(
        useCallback(() => {
            getUserAuth();
            onRefresh();
        }, [])
    );
    //console.log('>>>>>>>>>>>>>>', state.all);
    const onRefresh = () => {
        getSingleClient(state.client.id, (c) => setState((s) => ({ ...s, client: c })));
        actionActions.getAllWR(
            role == 3
                ? `/users/${user.id}/clients/${state.client.id}`
                : role == 1
                ? `/clients/${state.client.id}`
                : `/clients/${state.client.id}/users/${user.id}`,
            () => setState((s) => ({ ...s, loading: true })),
            (all) => setState((s) => ({ ...s, all, loading: false })),
            (error) => setState((s) => ({ ...s, error, loading: false }))
        );
    };
    const deleteActionAlert = (id) => {
        Alert.alert({
            title: state.client.name + ' löschen',
            message: 'Achtung!\nBeim Löschen werden alle Aktionen, Märktedaten und Märktebilder gelöscht',
            type: 'error',
            action: [
                { text: 'Löschen', onPress: () => deleteClient(id, navigation.goBack()) },
                { text: 'Abbrechen', onPress: () => {} },
            ],
        });
    };

    const headerSecondary = () => (
        <View>
            {state.client.logo?.path ? (
                <Image
                    source={{ uri: getImageUrl(`${state.client.logo.path}/${state.client.logo.name}`) }}
                    style={{ width: '100%', height: device != DeviceType.PHONE ? 250 : 150 }}
                />
            ) : (
                <View
                    style={{
                        width: '100%',
                        height: 150,
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#000',
                    }}
                >
                    <Text primaryColor header bold>
                        {state.client.name}
                    </Text>
                </View>
            )}
            <View
                style={{
                    backgroundColor: BaseColor.whiteColor,
                    padding: 15,
                    borderBottomColor: BaseColor.grayTransparent,
                    borderBottomWidth: 1,
                }}
            >
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                    <View style={{ paddingRight: 5 }}>
                        {state.client.logo?.path != null ? (
                            <View>
                                <View
                                    style={{
                                        borderRadius: 50,
                                        borderWidth: 2,
                                        position: 'relative',
                                        borderStyle: 'solid',
                                        borderColor: BaseColor.grayTransparent,
                                        overflow: 'hidden',
                                    }}
                                >
                                    <Image
                                        source={{ uri: getImageUrl(`${state.client.logo.path}/${state.client.logo.name}`) }}
                                        style={{ width: 50, height: 50 }}
                                    />
                                </View>
                            </View>
                        ) : (
                            <View
                                style={{
                                    borderRadius: 50,
                                    borderWidth: 2,
                                    borderColor: BaseColor.grayTransparent,
                                    width: 50,
                                    height: 50,
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    backgroundColor: '#000',
                                }}
                            >
                                <Text primaryColor title3>
                                    {state.client.name[0]}
                                    {state.client.name[1]}
                                </Text>
                            </View>
                        )}
                    </View>
                    <View>
                        <Text semibold>{state.client.name}</Text>
                        {state.client.street && (
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <View style={{ paddingRight: 3 }}>
                                    <Icon name="location-outline" type="ii" size={13} color={BaseColor.grayColor} />
                                </View>
                                <Text footnote grayColor>
                                    {state.client.street}, {state.client.postalCode} {state.client.city}
                                </Text>
                            </View>
                        )}
                    </View>
                </View>
                {state.client.description && (
                    <View style={{ paddingTop: 5 }}>
                        <Text>{state.client.description}</Text>
                    </View>
                )}

                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <View style={{ flex: 1, alignItems: 'flex-start' }}>
                        {(state.all?.length > 0 || !state.loading) && (
                            <Text semibold>
                                {state.all.length} {state.all.length > 1 ? 'Aktionen' : 'Aktion'}
                            </Text>
                        )}
                    </View>
                    <View
                        style={{
                            flexDirection: 'row',
                            marginTop: 10,

                            borderRadius: 20,
                            backgroundColor: BaseColor.grayLight,
                        }}
                    >
                        <TouchableOpacity
                            style={{
                                paddingHorizontal: 15,
                                paddingVertical: 5,
                                borderRightWidth: 1,
                                borderColor: BaseColor.grayTransparent,
                            }}
                            onPress={() => state.client.phonenumber && Linking.openURL(`tel:${state.client.phonenumber}`)}
                        >
                            <Icon name="phone" type="ad" size={20} color={state.client.phonenumber ? 'black' : BaseColor.grayTransparent} />
                        </TouchableOpacity>
                        <TouchableOpacity
                            style={{ paddingVertical: 5, paddingHorizontal: 15 }}
                            onPress={() => state.client.email && Linking.openURL(`mailto:${state.client.email}`)}
                        >
                            <Icon name="mail" type="ad" size={20} color={state.client.email ? 'black' : BaseColor.grayTransparent} />
                        </TouchableOpacity>
                    </View>
                </View>
            </View>
            <View style={{ padding: 15 }}>
                <Text headline bold>
                    Aktionen
                </Text>
            </View>
        </View>
    );

    const adminAction = () => (
        <View style={{ top: 5, right: 5, position: 'absolute' }}>
            <Menu
                activator={() => (
                    <View
                        style={[
                            {
                                backgroundColor: BaseColor.whiteColor,
                                height: 40,
                                width: 40,
                                borderRadius: 30,
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                        ]}
                    >
                        <Icon name="more-vert" type="mi" size={20} color={BaseColor.text} enableRTL={true} />
                    </View>
                )}
                actionItems={[
                    {
                        text: 'Bearbeiten',
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    width: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon name="edit" type="ad" size={20} color={BaseColor.text} />
                            </View>
                        ),
                        action: () => navigation.navigate({ name: 'EditClient', params: { client: state.client } }),
                    },
                    user.id == 19
                        ? {
                              text: 'Löschen',
                              leftIcon: () => (
                                  <View
                                      style={{
                                          backgroundColor: BaseColor.grayLight,
                                          height: 45,
                                          width: 45,
                                          borderRadius: 15,
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                      }}
                                  >
                                      <Icon name="delete" type="ad" size={20} color={BaseColor.text} />
                                  </View>
                              ),
                              action: () => deleteActionAlert(state.client.id),
                          }
                        : {},
                ]}
            />
        </View>
    );
    const onLayoutRootView = useCallback(async () => {
        await SplashScreen.hideAsync();
    }, []);

    return (
        <View style={{ flex: 1, backgroundColor: BaseColor.grayLight }} onLayout={onLayoutRootView}>
            {role != 2 && (
                <Header
                    title={state.client.name}
                    shadow
                    renderLeft={
                        role != 2
                            ? () => {
                                  return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                              }
                            : undefined
                    }
                    onPressLeft={
                        role != 2
                            ? () => {
                                  navigation.goBack();
                              }
                            : undefined
                    }
                    renderRight={role == 1 ? adminAction : undefined}
                />
            )}
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1 }}
                >
                    <FlatList
                        contentContainerStyle={{ backgroundColor: BaseColor.grayLight, paddingBottom: 80 }}
                        refreshControl={
                            <RefreshControl colors={[colors.primary]} tintColor={colors.primary} refreshing={false} onRefresh={onRefresh} />
                        }
                        ListHeaderComponent={headerSecondary}
                        data={state.all.length == 0 && state.loading ? [1, 2, 3, 4, 5, 6] : state.all}
                        renderItem={({ item }) => (
                            <View style={{ paddingHorizontal: 15 }}>
                                {item?.id ? <ActionListItem navigation={navigation} item={item} /> : <ActionListItem loading={true} />}
                            </View>
                        )}
                        keyExtractor={(item) => `ClientListItem ${item?.id ?? item}`}
                    />
                    {role == 1 && (
                        <View style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                            <View style={{ padding: 15, alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    style={{ height: 40, backgroundColor: 'white' }}
                                    textColor={'black'}
                                    icon={<Icon name="plus" size={25} type="ad" color={BaseColor.primary} />}
                                    onPress={() => navigation.navigate('ActionNew', { client: state.client })}
                                >
                                    {' '}
                                    Neue Aktion anlegen
                                </Button>
                            </View>
                        </View>
                    )}
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
}

function mapState(state) {
    const { user } = state.users;
    const { device } = state.offlineStorage;
    return { user, role: user?.roles[0], device };
}

const actionCreators = {
    getUserAuth: userActions.getUserAuth,
    getAllAction: actionActions.getAll,
    deleteClient: clientActions.delete,
    getSingleClient: clientActions.getSingle,
};

export default connect(mapState, actionCreators)(ActionList);
