import { clientConstants } from '../constants';

let initialState = {
    all: {},
};
export function clients(state = initialState, action) {
    switch (action.type) {
        case 'INIT':
            return initialState;
        case clientConstants.GETALL_REQUEST:
            return {
                ...state,
                all: { ...state.all, loading: true },
            };
        case clientConstants.GETALL_SUCCESS:
            return {
                ...state,
                all: action.clients,
            };
        case clientConstants.GETALL_FAILURE:
            return {
                ...state,
                all: { ...state.all, loading: false, error: action.error },
            };

        case clientConstants.DELETE_REQUEST:
            return {
                ...state,
                all: { ...state.all, rows: state.all.rows?.map((client) => (action.id == client.id ? { ...client, deleting: true } : client)) },
            };
        case clientConstants.DELETE_SUCCESS:
            return {
                ...state,
                all: {
                    count: state.all.count > 0 ? state.all.count - 1 : 0,
                    rows: state.all.rows?.filter((client) => action.id != client.id),
                },
            };
        case clientConstants.DELETE_FAILURE:
            return {
                ...state,
                all: {
                    ...state.all,
                    rows: state.all.rows?.map((client) =>
                        action.id == client.id ? { ...client, deleting: false, deletingError: action.error } : client
                    ),
                },
            };

        case clientConstants.CREATE_REQUEST:
            return {
                ...state,
                all: {
                    ...state.all,
                    creating: true,
                },
            };
        case clientConstants.CREATE_SUCCESS:
            return {
                ...state,
                all: { count: state.all.count + 1, rows: [...state.all.rows, action.client], creating: false },
            };
        case clientConstants.CREATE_FAILURE:
            return {
                ...state,
                all: {
                    ...state.all,
                    createError: action.error,
                    creating: false,
                },
            };

        case clientConstants.UPDATE_REQUEST:
            return {
                ...state,
                all: {
                    ...state.all,
                    rows: state.all.rows?.map((client) => (client.id == action.client.id ? { ...client, updating: true } : client)),
                },
            };
        case clientConstants.UPDATE_SUCCESS:
            return {
                ...state,
                all: {
                    ...state.all,
                    rows: state.all.rows?.map((client) => (client.id == action.client.id ? { ...action.client, updating: false } : client)),
                },
            };
        case clientConstants.UPDATE_FAILURE:
            return {
                ...state,
                all: {
                    ...state.all,
                    rows: state.all.rows?.map((client) => {
                        if (client.id == action.client.id) {
                            const { updating, ...clientCopy } = client;
                            return { ...state, ...clientCopy, updatingError: action.error };
                        }
                    }),
                },
            };

        case clientConstants.GETBYID_REQUEST:
            return {
                ...state,
                current: { loading: true },
            };
        case clientConstants.GETBYID_SUCCESS:
            return {
                ...state,
                current: action.single,
            };
        case clientConstants.GETBYID_FAILURE:
            return {
                ...state,
                current: { ...state.singles, error: action.error, loading: false },
            };
        case clientConstants.LOGOUT_SUCCESS:
            return initialState;
        default:
            return state;
    }
}
