import React from 'react';
import { GestureHandlerRootView } from 'react-native-gesture-handler';
import { store, persistor } from 'app/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Navigator from './navigation';
import { LogBox, View } from 'react-native';
import './styles.css';

LogBox.ignoreAllLogs(true);

export default function App() {
    return (
        <GestureHandlerRootView style={{ flex: 1 }}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <Navigator />
                </PersistGate>
            </Provider>
        </GestureHandlerRootView>
    );
}
