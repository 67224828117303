import React, { useEffect, useState } from 'react';
import { View, FlatList, TouchableOpacity, StatusBar, Linking, Platform, ScrollView, ImageBackground } from 'react-native';
//import { BaseStyle, BaseColor, useTheme } from '@config';
import { Image, Header, SafeAreaView, Icon, Text } from '@components';
import styles from './styles';
import { getImageUrl } from '../../helpers/functions';
import * as NavigationBar from 'expo-navigation-bar';
import SwipeableViews from 'react-swipeable-views';
import { bindKeyboard } from 'react-swipeable-views-utils';
import { BaseColor, BaseStyle } from '../../config';
import { deviceDimentions } from '../../utils';
import { AutoSizeImage } from '../../components';
export default function PreviewImage({ navigation, route }) {
    let flatListRef = null;
    let swiperRef = null;

    const [images, setImages] = useState(route.params?.gallery ?? []);
    const [title] = useState(route.params?.title ?? '');
    const [indexSelected, setIndexSelected] = useState(route.params?.index ?? 0);
    const [currentImage, setCurrentImage] = useState(images[0]);
    const [arrowVisibility, setArrowVisibility] = useState(false);

    const BindKeyboardSwipeableViews = bindKeyboard(SwipeableViews);
    useEffect(() => {
        if (flatListRef != null && indexSelected >= 0) {
            // flatListRef.scrollToIndex({
            //     animated: true,
            //     index: indexSelected,
            // });
            // onSelect(index);
        }
    }, [flatListRef]);
    useEffect(() => {
        if (Platform.OS === 'android') {
            StatusBar.setBarStyle('light-content', true);
            StatusBar.setBackgroundColor('#222');
            NavigationBar.setBackgroundColorAsync('#222');
            NavigationBar.setButtonStyleAsync('light');
        }
        return () => {
            if (Platform.OS === 'android') {
                StatusBar.setBarStyle('dark-content', true);
                StatusBar.setBackgroundColor('white');
                NavigationBar.setBackgroundColorAsync('white');
                NavigationBar.setButtonStyleAsync('dark');
            }
        };
    }, []);
    /**
     * call when select image
     *
     * @param {*} indexSelected
     */
    const onSelect = (indexSelected) => {
        setIndexSelected(indexSelected);

        setImages((i) =>
            i.map((item, index) => {
                if (index == indexSelected) {
                    setCurrentImage(item);
                    return {
                        ...item,
                        selected: true,
                    };
                } else {
                    return {
                        ...item,
                        selected: false,
                    };
                }
            })
        );
        // flatListRef.scrollToIndex({
        //     animated: true,
        //     index: indexSelected,
        // });
    };

    const onTouchImage = (touched) => {
        if (touched == indexSelected) return;
        setIndexSelected(touched);
        //swiperRef.scrollBy(touched - indexSelected, false);
    };

    return (
        <View style={{ flex: 1, backgroundColor: '#222' }}>
            <Header
                title={`${indexSelected + 1}/${images?.length}`}
                titleColor={BaseColor.whiteColor}
                renderLeft={() => {
                    return <Icon name="arrowleft" type="ad" size={23} color={BaseColor.whiteColor} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
                barStyle="light-content"
                renderRight={
                    images[indexSelected]?.id > 0
                        ? () => <Icon name="download" type="ad" size={23} color={BaseColor.whiteColor} style={{ marginRight: 5 }} />
                        : undefined
                }
                onPressRight={() => {
                    Linking.openURL(getImageUrl(`${images[indexSelected]?.path}/${images[indexSelected]?.name}`));
                }}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left', 'bottom']}>
                <View
                    style={{
                        position: 'relative',
                        flex: 1,
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                    onMouseEnter={() => {
                        setArrowVisibility(true);
                    }}
                    onMouseLeave={() => setArrowVisibility(false)}
                >
                    {currentImage && (
                        <AutoSizeImage
                            uri={
                                currentImage.id > 0 ? getImageUrl(`${currentImage.path}/${currentImage.name}`) : URL.createObjectURL(currentImage.uri)
                            }
                        />
                    )}

                    {arrowVisibility && (
                        <View style={{ position: 'absolute', left: 0, bottom: 0, top: 0 }}>
                            <View style={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <TouchableOpacity
                                    onPress={() => indexSelected > 0 && onSelect(indexSelected - 1)}
                                    style={{ padding: 20, backgroundColor: BaseColor.whiteColor + 'cc' }}
                                >
                                    <View>
                                        <Icon name="chevron-left" size={25} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    )}
                    {arrowVisibility && (
                        <View style={{ position: 'absolute', right: 0, bottom: 0, top: 0 }}>
                            <View style={{ justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <TouchableOpacity
                                    onPress={() => indexSelected + 1 < images.length && onSelect(indexSelected + 1)}
                                    style={{ padding: 20, backgroundColor: BaseColor.whiteColor + 'cc' }}
                                >
                                    <View style={{ justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%' }}>
                                        <Icon name="chevron-right" size={25} />
                                    </View>
                                </TouchableOpacity>
                            </View>
                        </View>
                    )}
                </View>
                <View>
                    <ScrollView horizontal style={{ padding: 5, paddingLeft: 0 }}>
                        {images.map((image, key) => {
                            return (
                                <View style={{ paddingLeft: 5 }}>
                                    <TouchableOpacity
                                        style={{ borderWidth: 1, borderColor: indexSelected == key ? BaseColor.primary : '#222' }}
                                        onPress={() => onSelect(key)}
                                    >
                                        <img
                                            key={`image1${key}`}
                                            style={{ width: 100, height: 100, objectFit: 'cover' }}
                                            src={image.id > 0 ? getImageUrl(`${image.path}/${image.name}`) : URL.createObjectURL(image.uri)}
                                        />
                                    </TouchableOpacity>
                                </View>
                            );
                        })}
                    </ScrollView>
                </View>
            </SafeAreaView>
        </View>
    );
}
