import React, { useState } from 'react';
import { View, TouchableHighlight, Platform, ActivityIndicator, Alert } from 'react-native';
import { Image, Text, Icon } from '@components';
import { BaseColor } from '@config';
import styles from './styles';
import { Placeholder, PlaceholderLine, PlaceholderMedia, Fade } from 'rn-placeholder';
import { connect, useDispatch } from 'react-redux';
import Menu from '../Menu';
import { BaseStyle, Images } from '../../config';
import { clientActions } from '../../redux/actions';
import { getImageUrl } from '../../helpers/functions';
import { DeviceType } from 'expo-device';

function ClientListItem(props) {
    const { item, loading, userId, style, navigation, deleteClient, device, role } = props;
    const [pressed, setpressed] = useState(false);
    const deleteActionAlert = (id) => {
        Alert.alert({
            title: item.name + ' löschen',
            message: 'Achtung!\nBeim Löschen werden alle Aktionen, Märktedaten und Märktebilder gelöscht',
            type: 'error',
            action: [
                { text: 'Löschen', onPress: () => deleteClient(id) },
                { text: 'Abbrechen', onPress: () => {} },
            ],
        });
    };
    const adminAction = () => (
        <View style={{ top: 5, right: 5, position: 'absolute' }}>
            <Menu
                activator={() => (
                    <View
                        style={[
                            {
                                backgroundColor: BaseColor.whiteColor,
                                height: 35,
                                width: 35,
                                borderRadius: 30,
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                            Platform.OS == 'android' && BaseStyle.boxWithShadowBottom,
                        ]}
                    >
                        <Icon name="more-vert" type="mi" size={20} color={BaseColor.text} enableRTL={true} />
                    </View>
                )}
                actionItems={[
                    {
                        text: 'Bearbeiten',
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    width: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon name="edit" type="ad" size={20} color={BaseColor.text} />
                            </View>
                        ),
                        action: () => navigation.navigate({ name: 'EditClient', params: { client: item } }),
                    },
                    userId == 19
                        ? {
                              text: 'Löschen',
                              leftIcon: () => (
                                  <View
                                      style={{
                                          backgroundColor: BaseColor.grayLight,
                                          height: 45,
                                          width: 45,
                                          borderRadius: 15,
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                      }}
                                  >
                                      <Icon name="delete" type="ad" size={20} color={BaseColor.text} />
                                  </View>
                              ),
                              action: () => deleteActionAlert(item.id),
                          }
                        : {},
                ]}
            />
        </View>
    );
    const renderGrid = () => {
        if (loading) {
            return (
                <View style={[styles.default, style, { overflow: 'hidden' }]}>
                    <Placeholder Animation={Fade}>
                        <View>
                            <PlaceholderMedia style={[styles.girdImage, { borderRadius: 3 }]} />
                            <PlaceholderLine style={{ width: '100%', marginTop: 15 }} />
                        </View>
                    </Placeholder>
                </View>
            );
        }
        return (
            <View>
                <TouchableHighlight
                    underlayColor={'#00000010'}
                    activeOpacity={1}
                    style={{
                        overflow: 'hidden',
                        backgroundColor: BaseColor.whiteColor,
                        borderBottomColor: BaseColor.grayTransparent,
                        borderBottomWidth: 1,
                    }}
                    onPressIn={() => setpressed(true)}
                    onPressOut={() => setpressed(false)}
                    onPress={() =>
                        navigation.navigate('ActionList', {
                            client: item,
                        })
                    }
                >
                    <View>
                        <View>
                            {item.logo ? (
                                <Image
                                    style={[styles.girdImage, { height: 150 }]}
                                    source={{ uri: getImageUrl(`${item.logo.path}/${item.logo.name}`) }}
                                />
                            ) : (
                                <View
                                    style={{
                                        width: '100%',
                                        height: 150,
                                        backgroundColor: '#000',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}
                                >
                                    <Text bold title1 primaryColor>
                                        {item.name && item.name[0]}
                                        {item.name && item.name[1]}
                                    </Text>
                                </View>
                            )}
                        </View>
                        <View style={{ padding: 10 }}>
                            <Text semibold>{item.name}</Text>
                        </View>
                        {/* <View>
                            <Text footnote grayColor>
                                {item.description}
                            </Text>
                        </View> */}
                    </View>
                </TouchableHighlight>
                {role == 1 && !item.deleting && adminAction()}
                {item.deleting && (
                    <View style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', transform: [{ scale: 1.1 }] }}>
                        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: '#00000080' }}>
                            <ActivityIndicator size={'large'} color={BaseColor.whiteColor} />
                        </View>
                    </View>
                )}
                {/* {pressed && (
                    <View style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%', transform: [{ scale: 1.05 }] }}>
                        <View style={{ flex: 1, backgroundColor: '#00000010' }}></View>
                    </View>
                )} */}
            </View>
        );
    };

    return renderGrid();
}

function mapState(state) {
    const { user } = state.users;
    const { device } = state.offlineStorage;
    return { role: user?.roles[0], userId: user.id, device };
}

const actionCreators = {
    deleteClient: clientActions.delete,
};

export default connect(mapState, actionCreators)(ClientListItem);
