import React, { useEffect } from 'react';
import { View, TouchableOpacity, StatusBar, useColorScheme, Platform } from 'react-native';
import Text from '../Text';
import styles from './styles';
import PropTypes from 'prop-types';
import { BaseColor, BaseStyle } from '../../config';
import { SafeAreaView } from 'react-native-safe-area-context';

export default function Header(props) {
    const {
        style,
        styleLeft,
        styleCenter,
        styleRight,
        styleRightSecond,
        border,
        title,
        subTitle,
        onPressLeft,
        onPressRight,
        onPressRightSecond,
        renderLeft,
        renderRightSecond,
        renderRight,
        barStyle,
        titleColor,
        shadow,
        backgroundColor,
        renderLefUntouchable,
    } = props;

    const isDarkMode = useColorScheme() === 'dark';

    // useEffect(() => {
    //     let option = isDarkMode ? 'light-content' : 'dark-content';
    //     StatusBar.setBarStyle(option, true);
    // }, [barStyle, isDarkMode]);

    return (
        <SafeAreaView style={[{ width: '100%' }]} edges={['top', 'left', 'right']}>
            <View
                style={[
                    styles.contain,
                    style,
                    backgroundColor && { backgroundColor: backgroundColor ?? BaseColor.whiteColor },
                    shadow &&
                        (Platform.OS === 'android'
                            ? { ...BaseStyle.boxWithShadowBottom, backgroundColor: backgroundColor ?? BaseColor.whiteColor }
                            : {
                                  borderBottomWidth: 1,
                                  borderBottomColor: BaseColor.dividerColor,
                                  backgroundColor: backgroundColor ?? BaseColor.whiteColor,
                              }),
                    border && {
                        borderBottomWidth: 1,
                        borderBottomColor: BaseColor.dividerColor,
                        backgroundColor: backgroundColor ?? BaseColor.whiteColor,
                    },
                ]}
            >
                <View style={{ flex: 1 }}>
                    {!renderLefUntouchable ? (
                        <TouchableOpacity style={[styles.contentLeft, styleLeft]} onPress={onPressLeft}>
                            {renderLeft()}
                        </TouchableOpacity>
                    ) : (
                        <View style={[styles.contentLeft, styleLeft]} onPress={onPressLeft}>
                            {renderLeft()}
                        </View>
                    )}
                </View>

                <View style={[styles.contentCenter, styleCenter]}>
                    <Text headline semibold style={{ color: titleColor ?? BaseColor.firstColor }} numberOfLines={1}>
                        {title}
                    </Text>
                    {subTitle != '' && (
                        <Text numberOfLines={1} caption1 light style={{ color: titleColor ?? BaseColor.firstColor }}>
                            {subTitle}
                        </Text>
                    )}
                </View>

                <View style={styles.right}>
                    <TouchableOpacity style={[styles.contentRightSecond, styleRightSecond]} onPress={onPressRightSecond}>
                        {renderRightSecond()}
                    </TouchableOpacity>
                    <TouchableOpacity style={[styles.contentRight, styleRight]} onPress={onPressRight}>
                        {renderRight()}
                    </TouchableOpacity>
                </View>
            </View>
        </SafeAreaView>
    );
}

Header.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    styleLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    styleCenter: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    styleRight: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    styleRightSecond: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    renderLeft: PropTypes.func,
    renderRight: PropTypes.func,
    renderRightSecond: PropTypes.func,
    onPressRightSecond: PropTypes.func,
    onPressLeft: PropTypes.func,
    onPressRight: PropTypes.func,
    title: PropTypes.any,
    subTitle: PropTypes.string,
    barStyle: PropTypes.string,
    shadow: PropTypes.bool,
};

Header.defaultProps = {
    style: {},
    styleLeft: {},
    styleCenter: {},
    styleRight: {},
    styleRightSecond: {},
    renderLeft: () => {},
    renderRight: () => {},
    renderRightSecond: () => {},
    onPressLeft: () => {},
    onPressRight: () => {},
    onPressRightSecond: () => {},
    title: '',
    subTitle: '',
    barStyle: '',
    shadow: false,
};
