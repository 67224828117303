import React, { useCallback } from 'react';
import { RefreshControl, ScrollView, TouchableHighlight, View } from 'react-native';
import { useTheme } from '@config';
import { Header, SafeAreaView, Icon } from '@components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { userActions } from '@actions';
import { useFocusEffect } from '@react-navigation/native';
import { userSelector } from '../../selectors';
import * as SplashScreen from 'expo-splash-screen';
import { Text } from '../../components';
import { BaseColor, BaseStyle } from '../../config';
import { useEffect } from 'react';
import { useState } from 'react';
import { ActivityIndicator } from 'react-native';
import { Linking } from 'react-native';
import { actionActions } from '../../redux/actions';

function AssignClient({ getSingleAction, getUserAuth, assignUser, getAllUser, allUser, navigation, route }) {
    const { colors } = useTheme();
    const dispatch = useDispatch();
    const user = useSelector(userSelector.user);
    
    const [state, setState] = useState({
        action: route.params.action,
        loading: false,
    });

    useFocusEffect(
        useCallback(() => {
            getUserAuth();
            return () => {};
        }, [])
    );

    useEffect(() => {
        onRefresh();
    }, []);
    function onRefresh() {
        getSingleAction(`${state.action.id}?onlyUsers=true`, (resp) => {
            setState((s) => ({ ...s, action: { ...resp, stores: [] } }));
        });
        getAllUser();
    }
    if (!user) {
        return <View></View>;
    }
    const onLayoutRootView = useCallback(async () => {
        await SplashScreen.hideAsync();
    }, []);
    const downloadFromUrl = async (name) => {
        // const filename = 'Reporting-CSV-Vorlage.csv';
        // const result = await FileSystem.downloadAsync(
        //     'http://reporting-app-daten.gate-internet.de/uploads/Reporting-CSV-Vorlage.csv',
        //     FileSystem.documentDirectory + filename
        // );
        Linking.openURL(`http://reporting-app-daten.gate-internet.de/uploads/csv/${name}.csv`);
        // save(result.uri, filename, result.headers['content-Type']);
    };
    function selectUser(user) {
        let currentusers = state.action.users;
        let newUsers = isSelected(user.id) ? currentusers.filter((u) => u.id != user.id) : [...currentusers, user];
        setState((s) => ({ ...s, loading: false, action: { ...s.action, users: newUsers } }));
    }
    function isSelected(id) {
        return state.action?.users?.findIndex((user) => user.id == id) >= 0;
    }
    return (
        <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
            <Header
                title="Zuweisung verwaltung"
                subTitle={state.action.name}
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" color={colors.text} enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <ScrollView
                    refreshControl={
                        <RefreshControl colors={[BaseColor.primary]} tintColor={BaseColor.primary} refreshing={false} onRefresh={onRefresh} />
                    }
                >
                    <View style={{ padding: 20 }}>
                        {!state.action?.users ? (
                            <View>
                                <ActivityIndicator color={BaseColor.primary} size={'large'} />
                            </View>
                        ) : (
                            allUser?.rows
                                ?.filter((user) => user?.roles[0]?.value == 2 && user.clientId == state.action?.clientId)
                                ?.map((user, index) => (
                                    <TouchableHighlight
                                        underlayColor={BaseColor.grayLight}
                                        activeOpacity={1}
                                        style={{
                                            flexDirection: 'row',
                                            paddingVertical: 10,
                                            borderBottomWidth: 1,
                                            borderBottomColor: BaseColor.grayLight,
                                            alignItems: 'center',
                                            opacity: 1,
                                        }}
                                        key={`${user.firstname} ${user.lastname}` + index}
                                        onPress={() =>
                                            state.loading == false &&
                                            assignUser(
                                                state.action.id,
                                                user.id,
                                                () => setState((s) => ({ ...s, loading: user.id })),
                                                () => selectUser(user),
                                                () => setState((s) => ({ ...s, loading: false }))
                                            )
                                        }
                                    >
                                        <View style={{ flexDirection: 'row' }}>
                                            <View
                                                style={{
                                                    marginRight: 10,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        backgroundColor: BaseColor.grayLight,
                                                        height: 40,
                                                        width: 40,
                                                        borderRadius: 15,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Icon name="user" type="ad" size={18} color={BaseColor.text} />
                                                </View>
                                            </View>

                                            <View style={{ justifyContent: 'center', flex: 1 }}>
                                                <Text callout1>{`${user.firstname} ${user.lastname}`}</Text>
                                            </View>
                                            <View
                                                style={{
                                                    marginRight: 10,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        height: 40,
                                                        width: 40,
                                                        borderRadius: 15,
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {state.loading == user.id ? (
                                                        <ActivityIndicator color={BaseColor.primary} />
                                                    ) : (
                                                        <Icon
                                                            name="check"
                                                            type="ad"
                                                            size={20}
                                                            color={isSelected(user.id) ? BaseColor.primary : BaseColor.grayTransparent}
                                                        />
                                                    )}
                                                </View>
                                            </View>
                                        </View>
                                    </TouchableHighlight>
                                ))
                        )}
                    </View>
                </ScrollView>
            </SafeAreaView>
        </View>
    );
}

const menuIcon = {
    width: 60,
    height: 60,
    borderRadius: 50,
    shadowColor: '#000',
    shadowOffset: {
        width: 0,
        height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 20,
    alignItems: 'center',
    justifyContent: 'center',
};
function mapState(state) {
    const { user, all } = state.users;
    return { user, allUser: all };
}

const actionCreators = {
    getSingleAction: actionActions.getSingle,
    assignUser: actionActions.assignUser,
    getUserAuth: userActions.getUserAuth,
    getAllUser: userActions.getAll,
};

export default connect(mapState, actionCreators)(AssignClient);
