import React from 'react';
import {View,  StyleSheet} from 'react-native';
import {View as AnimatableView} from 'react-native-animatable';
import StarButton from './StarButton';

const ANIMATION_TYPES = [
  'bounce',
  'flash',
  'jello',
  'pulse',
  'rotate',
  'rubberBand',
  'shake',
  'swing',
  'tada',
  'wobble',
];

const defaultProps = {
  activeOpacity: 0.2,
  animation: undefined,
  buttonStyle: {},
  containerStyle: {},
  disabled: false,
  emptyStar: 'star-o',
  emptyStarColor: 'gray',
  fullStar: 'star',
  fullStarColor: 'black',
  halfStar: 'star-half-o',
  halfStarColor: undefined,
  halfStarEnabled: false,
  icoMoonJson: undefined,
  iconSet: 'FontAwesome',
  maxStars: 5,
  rating: 0,
  reversed: false,
  starSize: 40,
  starStyle: {},
  selectedStar: () => {},
};

function StarRating(props) {
  const starRef = [];
  const {
    activeOpacity,
    animation,
    buttonStyle,
    containerStyle,
    disabled,
    emptyStar,
    emptyStarColor,
    fullStar,
    fullStarColor,
    halfStar,
    halfStarColor,
    halfStarEnabled,
    icoMoonJson,
    iconSet,
    maxStars,
    rating,
    reversed,
    starSize,
    starStyle,
    selectedStar,
  } = props;

  const onStarButtonPress = rating => {
    selectedStar(rating);
  };

  const newContainerStyle = {
    flexDirection: reversed ? 'row-reverse' : 'row',
    justifyContent: 'space-between',
    ...StyleSheet.flatten(containerStyle),
  };

  // Round rating down to nearest .5 star
  let starsLeft = Math.round(rating * 2) / 2;
  const starButtons = [];

  for (let i = 0; i < maxStars; i++) {
    let starIconName = emptyStar;
    let finalStarColor = emptyStarColor;

    if (starsLeft >= 1) {
      starIconName = fullStar;
      finalStarColor = fullStarColor;
    } else if (starsLeft === 0.5) {
      starIconName = halfStar;
      if (halfStarColor) {
        finalStarColor = halfStarColor;
      } else {
        finalStarColor = fullStarColor;
      }
    }

    const starButtonElement = (
      <AnimatableView
        key={i}
        ref={node => {
          starRef.push(node);
        }}>
        <StarButton
          activeOpacity={activeOpacity}
          buttonStyle={buttonStyle}
          disabled={disabled}
          halfStarEnabled={halfStarEnabled}
          icoMoonJson={icoMoonJson}
          iconSet={iconSet}
          onStarButtonPress={event => {
            if (animation && ANIMATION_TYPES.includes(animation)) {
              for (let s = 0; s <= i; s++) {
                starRef[s][animation](1000 + s * 200);
              }
            }
            onStarButtonPress(event);
          }}
          rating={i + 1}
          reversed={reversed}
          starColor={finalStarColor}
          starIconName={starIconName}
          starSize={starSize}
          starStyle={starStyle}
        />
      </AnimatableView>
    );

    starButtons.push(starButtonElement);
    starsLeft -= 1;
  }

  return (
    <View style={newContainerStyle} pointerEvents={disabled ? 'none' : 'auto'}>
      {starButtons}
    </View>
  );
}

StarRating.defaultProps = defaultProps;

export default StarRating;
