import React, { useEffect, useRef, useState } from 'react';
import { Animated, Platform, TouchableOpacity, Vibration, View } from 'react-native';
import { Icon, Text, TextInput } from '../../components';
import { BaseColor } from '../../config';
import { CheckBox } from 'react-native-web';
import { Switch } from 'react-native-gesture-handler';
import { showMessage } from 'react-native-flash-message';

export default function FormTextInput({ input, setInput, deleteInput, editing, checkEditing, checkEditableInput, checkAllInputs }) {
    const [state, setState] = useState({ editable: true, ...input });
    const animationRef = useRef(new Animated.Value(0));
    useEffect(() => {
        !input.edit && checkEditing(input.order);
    }, []);
    const setForm = (name, value) => {
        setState((s) => ({ ...s, [name]: value }));
    };

    const cancel = () => {
        let submitable = checkInput(false, false, true);
        setState((s) => ({ ...s, ...input, editable: false, submitable }));
        setInput({ submitable });
        checkEditing(null);
    };
    const save = () => {
        let submitable = checkInput();
        if (submitable) {
            setInput({
                required: state.required,
                multiline: state.multiline,
                label: state.label,
                placeholder: state.placeholder,
                description: state.description,
                submitable,
            });
            setState((s) => ({ ...s, editable: false, submitable }));
            checkEditing(null);
        }
    };
    const edit = () => {
        if (!checkEditing(input.order)) {
            setState((s) => ({ ...s, ...input, editable: true }));
        }
    };
    const checkInput = (vibrate, light, parentInput) => {
        if (input.istNew) {
            setInput({ istNew: false });
            return;
        }
        let newState = parentInput ? { ...state, ...input } : state;
        let result = newState.label;
        if (!newState.label) {
            newState = { ...newState, labelError: false };
        }

        setState({ ...newState, inputError: !result });
        if ((!result && vibrate !== false) || light) {
            highlight();
            Vibration.vibrate(50);
            setState((s) => ({ ...s }));
        }
        if (result && light) {
            showMessage({
                message: 'Bitte markierte Abfrage zuerst speichern!',
                icon: 'warning',
            });
        }
        return result;
    };
    const highlight = () => {
        Animated.sequence([
            Animated.timing(animationRef.current, {
                toValue: 1,
                // set the duration
                duration: 500,
                useNativeDriver: false,
            }),
            // delay is optional
            Animated.delay(1000),
            Animated.timing(animationRef.current, {
                toValue: 0,
                duration: 500,
                // useNativeDriver:true, might not work with the all properties that you need to animate. true might improve animation performance
                useNativeDriver: false,
            }),
        ]).start();
    };
    const descriptionInput = useRef(null);
    const placeholderInput = useRef(null);

    useEffect(() => {
        editing == input.order && checkInput(true, true);
    }, [checkEditableInput]);
    useEffect(() => {
        editing == input.order ? checkInput(true, true) : checkInput();
    }, [checkAllInputs]);
    return (
        <Animated.View
            style={{
                borderBottomColor: BaseColor.grayTransparent,
                borderBottomWidth: 1,
                marginBottom: 10,
                paddingHorizontal: 15,
                backgroundColor: animationRef.current?.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['#fff', '#ccc'],
                }),
            }}
        >
            {!state.editable ? (
                <View>
                    <View style={{ paddingBottom: 5 }}>
                        <Text>
                            {input.label ?? 'Überschrift'}
                            {input.required && ' *'}
                        </Text>
                        {input.description && (
                            <Text grayColor footnote>
                                {input.description}
                            </Text>
                        )}
                    </View>
                    <TextInput
                        editable={false}
                        multiline={input.multiline}
                        placeholder={input.placeholder ?? 'Placeholder'}
                        style={{ borderRadius: 0 }}
                    />
                </View>
            ) : (
                <View style={{ marginTop: 15 }}>
                    <View style={{ padding: 15, backgroundColor: '#00000030' }}>
                        <View style={{ paddingBottom: 5 }}>
                            <Text>
                                {state.label ?? 'Überschrift'}
                                {state.required && ' *'}
                            </Text>
                            {state.description && (
                                <Text grayColor footnote>
                                    {state.description}
                                </Text>
                            )}
                        </View>
                        <TextInput
                            editable={false}
                            multiline={state.multiline}
                            placeholder={state.placeholder ?? 'Placeholder'}
                            style={{ borderRadius: 0 }}
                        />
                    </View>
                    <View>
                        <TouchableOpacity
                            onPress={() => setState((s) => ({ ...s, required: !s.required }))}
                            style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: Platform.OS === 'android' ? 0 : 10 }}
                        >
                            <View>
                                <Switch
                                    size={18}
                                    thumbColor={BaseColor.whiteColor}
                                    trackColor={{ true: BaseColor.primary, false: BaseColor.grayTransparent }}
                                    //ios_backgroundColor={BaseColor.primary}
                                    style={{ marginRight: 15 }}
                                    onValueChange={(v) => setForm('required', v)}
                                    value={state.required}
                                />
                            </View>
                            <View>
                                <Text>Pflichtfeld</Text>
                            </View>
                        </TouchableOpacity>
                        <TouchableOpacity
                            onPress={() => setState((s) => ({ ...s, multiline: !s.multiline }))}
                            style={{ flexDirection: 'row', alignItems: 'center', paddingVertical: Platform.OS === 'android' ? 0 : 10 }}
                        >
                            <View>
                                <Switch
                                    size={18}
                                    thumbColor={BaseColor.whiteColor}
                                    trackColor={{ true: BaseColor.primary, false: BaseColor.grayTransparent }}
                                    //ios_backgroundColor={BaseColor.primary}
                                    style={{ marginRight: 15 }}
                                    onValueChange={(v) => setForm('multiline', v)}
                                    value={state.multiline}
                                />
                            </View>
                            <View>
                                <Text>Mehrzeilig (zb. für langer Text)</Text>
                            </View>
                        </TouchableOpacity>
                        <TextInput
                            onChangeText={(v) => setForm('label', v)}
                            onFocus={() => {
                                setState({
                                    ...state,
                                    labelError: '',
                                });
                            }}
                            placeholder="Überschrift *"
                            success={!state.labelError}
                            value={state.label}
                            style={{ borderRadius: 0, marginBottom: 15, height: 40 }}
                            onSubmitEditing={() => placeholderInput.current.focus()}
                            textError={state.labelError}
                        />
                        <TextInput
                            inputRef={placeholderInput}
                            onChangeText={(v) => setForm('placeholder', v)}
                            onFocus={() => {
                                setState({
                                    ...state,
                                    placeholderError: '',
                                });
                            }}
                            placeholder="Platzhalter"
                            success={!state.placeholderError}
                            value={state.placeholder}
                            style={{ borderRadius: 0, marginBottom: 15, height: 40 }}
                            onSubmitEditing={() => descriptionInput.current.focus()}
                            textError={state.placeholderError}
                        />
                        <TextInput
                            inputRef={descriptionInput}
                            onChangeText={(v) => setForm('description', v)}
                            onFocus={() => {
                                setState({
                                    ...state,
                                    descriptionError: '',
                                });
                            }}
                            multiline
                            numberOfLines={5}
                            placeholder="Beschreibung/Anmerkung"
                            success={!state.descriptionError}
                            value={state.description}
                            style={{ borderRadius: 0 }}
                            textError={state.descriptionError}
                        />
                    </View>
                </View>
            )}
            <View
                style={{
                    flexDirection: 'row',
                    paddingTop: 5,
                    paddingBottom: 15,
                }}
            >
                <View style={{ flex: 1 }}>
                    {state.inputError ? (
                        <Text footnote errorColor>
                            Pflichtfelder ausfüllen
                        </Text>
                    ) : (
                        <Animated.View style={[{ opacity: animationRef.current }]}>
                            <Text footnote errorColor>
                                Bitte zuerst speichern!
                            </Text>
                        </Animated.View>
                    )}
                </View>
                <View
                    style={{
                        flexDirection: 'row',
                        marginTop: 3,

                        paddingVertical: 10,
                        borderRadius: 20,
                        backgroundColor: BaseColor.grayLight,
                    }}
                >
                    <TouchableOpacity
                        style={{ paddingHorizontal: 20, borderRightWidth: 1, borderColor: BaseColor.grayTransparent }}
                        onPress={state.editable ? save : edit}
                    >
                        <Icon name={state.editable ? 'check' : 'edit'} type="ad" size={20} />
                    </TouchableOpacity>
                    <TouchableOpacity style={{ paddingHorizontal: 20 }} onPress={state.editable ? cancel : deleteInput}>
                        <Icon name={state.editable ? 'close' : 'delete'} type="ad" size={20} />
                    </TouchableOpacity>
                </View>
            </View>
        </Animated.View>
    );
}
