import {StyleSheet} from 'react-native';
import * as Utils from '@utils';
import {BaseColor} from '../../config/theme';

export default StyleSheet.create({

  blockImage: {
    height: Utils.scaleWithPixel(250),
    width: '100%',
  },
  blockImage1: {
    height: Utils.scaleWithPixel(250),
    width: '50%',
  },
  tagStatus: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
  dot: {
    width: 4,
    height: 4,
    borderRadius: 2,
    backgroundColor: BaseColor.grayColor,
    marginHorizontal: 4,
  },
  iconLike: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  blockContentRate: {
    position: 'absolute',
    bottom: 5,
    right: 10,
  },
  blockLineMap: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 8,
  },
  blockLinePhone: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 5,
  },
  //list css
  listImage: {
    height: Utils.scaleWithPixel(100),
    width: Utils.scaleWithPixel(100),
    borderRadius: 5,
  },
  listImage1: {
    height: Utils.scaleWithPixel(70),
    width: Utils.scaleWithPixel(70),
    borderRadius: 5,
  },
  listContent: {
    flexDirection: 'row',
    borderRadius: 5,
    backgroundColor: '#ffffff',
    borderStyle: 'solid',
    borderColor: '#eee',
    borderWidth: 0.5,
    padding:8

  },
  listContentRight: {
    paddingLeft: 10,
    paddingVertical: 5,
    flex: 1,
  },
  lineRate: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 2,
  },
  listTagStatus: {
    position: 'absolute',
    top: 10,
    left: 10,
  },
  iconListLike: {
    position: 'absolute',
    bottom: 0,
    right: 0,
  },
  //gird css
  girdImage: {
    height: 100,
    width: '100%',
    //borderRadius:15,
  },
  girdImage1: {
    borderRadius: 5,
    height: 80,
    width: '50%',
  },
  tagGirdStatus: {
    position: 'absolute',
    top: 5,
    left: 5,
  },
  iconGirdLike: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
});
