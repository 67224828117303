import React, { useCallback, useEffect } from 'react';
import { View, ScrollView, RefreshControl, Alert } from 'react-native';
import { useTheme, Images } from '@config';
import { SafeAreaView, Icon, Text, Image } from '@components';
import styles from './styles';
import { connect } from 'react-redux';
import { BaseColor, BaseStyle } from '../../config';
import { useFocusEffect } from '@react-navigation/native';
import { Button } from '../../components';
import ClientListItem from '../../components/ClientListItem';
import * as SplashScreen from 'expo-splash-screen';
import { userActions } from '../../redux/actions/user.actions';
import { clientActions } from '../../redux/actions';

function Clients({ getUserAuth, getAllClient, allClient, user, navigation }) {
    const { colors } = useTheme();
    let role = user?.roles[0];
    useFocusEffect(
        useCallback(() => {
            getUserAuth();
            onRefresh();
        }, [])
    );

    const onRefresh = () => {
        getAllClient(role == 3 && user.id);
    };
    const renderList = () => {
        let list = (
            <View style={{ paddingLeft: 15, flexDirection: 'row', flexWrap: 'wrap' }}>
                {[1, 2, 3, 4, 5, 6].map((item, index) => {
                    return (
                        <View key={`ClientListItem ${index}`} style={{ width: '50%', paddingRight: 15 }}>
                            <ClientListItem loading={true} style={{ marginBottom: 15 }} />
                        </View>
                    );
                })}
            </View>
        );
        if (allClient?.count >= 0) {
            list =
                allClient?.rows?.length > 0 ? (
                    <View style={{ paddingLeft: 15, flexDirection: 'row', flexWrap: 'wrap' }}>
                        {allClient?.rows.map((item, index) => {
                            return (
                                <View key={`ClientListItem ${item.id}`} style={{ width: '50%', paddingRight: 15, paddingBottom: 15 }}>
                                    <ClientListItem navigation={navigation} item={item} />
                                </View>
                            );
                        })}
                    </View>
                ) : (
                    <View style={{ padding: 15, height: 200, alignItems: 'center', justifyContent: 'center' }}>
                        <Text>Keine Kunde gefunden</Text>
                    </View>
                );
        }

        return (
            <View>
                <View style={{ padding: 15, paddingBottom: 0 }}>
                    <View
                        style={{
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            marginBottom: 15,
                        }}
                    >
                        <View>
                            <Text title3 bold>
                                Alle Kunden
                            </Text>
                        </View>
                    </View>
                </View>
                {list}
            </View>
        );
    };
    const onLayoutRootView = useCallback(async () => {
        await SplashScreen.hideAsync();
    }, []);
    const renderContent = () => {
        return (
            <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
                <View style={[styles.contentHeader]}>
                    <View
                        style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <Image source={Images.logoW} style={{ width: 80, height: 40 }} resizeMode="contain" />
                    </View>
                </View>
                <ScrollView
                    style={{ flex: 1, backgroundColor: BaseColor.fieldColor }}
                    refreshControl={<RefreshControl colors={[colors.primary]} tintColor={colors.primary} refreshing={false} onRefresh={onRefresh} />}
                >
                    {renderList()}
                </ScrollView>
            </View>
        );
    };

    return (
        <SafeAreaView style={[BaseStyle.safeAreaView]} edges={['right', 'top', 'left']}>
            {renderContent()}
            {role == 1 && (
                <View style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                    <View style={{ padding: 15, alignItems: 'center', justifyContent: 'center' }}>
                        <Button
                            style={{ height: 40, backgroundColor: 'white' }}
                            textColor={'black'}
                            icon={<Icon name="plus" size={25} type="ad" color={BaseColor.primary} />}
                            onPress={() => navigation.navigate('NewClient')}
                        >
                            {' '}
                            Neue Kunde anlegen
                        </Button>
                    </View>
                </View>
            )}
        </SafeAreaView>
    );
}

function mapState(state) {
    const { all } = state.clients;
    const { user } = state.users;
    return { allClient: all, user };
}

const actionCreators = {
    getUserAuth: userActions.getUserAuth,
    getAllClient: clientActions.getAll,
};

export default connect(mapState, actionCreators)(Clients);
