export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    LOGOUT_REQUEST: 'USERS_LOGOUT_REQUEST',
    LOGOUT_SUCCESS: 'USERS_LOGOUT_SUCCESS',
    LOGOUT_FAILURE: 'USERS_LOGOUT_FAILURE',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    GETBYID_REQUEST: 'USERS_GETBYID_REQUEST',
    GETBYID_SUCCESS: 'USERS_GETBYID_SUCCESS',
    GETBYID_FAILURE: 'USERS_GETBYID_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    UPDATEPOST_REQUEST: 'USERS_UPDATEPOST_REQUEST',
    UPDATEPOST_SUCCESS: 'USERS_UPDATEPOST_SUCCESS',
    UPDATEPOST_FAILURE: 'USERS_UPDATEPOST_FAILURE',

    ISFAVORITE_REQUEST: 'USERS_ISFAVORITE_REQUEST',
    ISFAVORITE_SUCCESS: 'USERS_ISFAVORITE_SUCCESS',
    ISFAVORITE_FAILURE: 'USERS_ISTFAVORITE_FAILURE',

    GETNUMBEROFFAVORITE_REQUEST: 'USERS_GETNUMBEROFFAVORITE_REQUEST',
    GETNUMBEROFFAVORITE_SUCCESS: 'USERS_GETNUMBEROFFAVORITE_SUCCESS',
    GETNUMBEROFFAVORITE_FAILURE: 'USERS_GETNUMBEROFFAVORITE_FAILURE',

    GETFAVORITE_REQUEST: 'USERS_GETFAVORITE_REQUEST',
    GETFAVORITE_SUCCESS: 'USERS_GETFAVORITE_SUCCESS',
    GETFAVORITE_FAILURE: 'USERS_GETFAVORITE_FAILURE',

    UPDATEFAVORITE_REQUEST: 'USERS_UPDATEFAVORITE_REQUEST',
    UPDATEFAVORITE_SUCCESS: 'USERS_UPDATEFAVORITE_SUCCESS',
    UPDATEFAVORITE_FAILURE: 'USERS_UPDATEFAVORITE_FAILURE',

    GETNUMBEROFFOLLOWERS_REQUEST: 'USERS_GETNUMBEROFFOLLOWERS_REQUEST',
    GETNUMBEROFFOLLOWERS_SUCCESS: 'USERS_GETNUMBEROFFOLLOWERS_SUCCESS',
    GETNUMBEROFFOLLOWERS_FAILURE: 'USERS_GETNUMBEROFFOLLOWERS_FAILURE',

    GETFOLLOWERS_REQUEST: 'USERS_GETFOLLOWERS_REQUEST',
    GETFOLLOWERS_SUCCESS: 'USERS_GETFOLLOWERS_SUCCESS',
    GETFOLLOWERS_FAILURE: 'USERS_GETFOLLOWERS_FAILURE',

    UPDATEFOLLOWERS_REQUEST: 'USERS_UPDATEFOLLOWERS_REQUEST',
    UPDATEFOLLOWERS_SUCCESS: 'USERS_UPDATEFOLLOWERS_SUCCESS',
    UPDATEFOLLOWERS_FAILURE: 'USERS_UPDATEFOLLOWERS_FAILURE',

    GETNUMBEROFFOLLOWED_REQUEST: 'USERS_GETNUMBEROFFOLLOWED_REQUEST',
    GETNUMBEROFFOLLOWED_SUCCESS: 'USERS_GETNUMBEROFFOLLOWED_SUCCESS',
    GETNUMBEROFFOLLOWED_FAILURE: 'USERS_GETNUMBEROFFOLLOWED_FAILURE',

    GETFOLLOWED_REQUEST: 'USERS_GETFOLLOWED_REQUEST',
    GETFOLLOWED_SUCCESS: 'USERS_GETFOLLOWED_SUCCESS',
    GETFOLLOWED_FAILURE: 'USERS_GETFOLLOWED_FAILURE',

    UPDATEFOLLOWED_REQUEST: 'USERS_UPDATEFOLLOWED_REQUEST',
    UPDATEFOLLOWED_SUCCESS: 'USERS_UPDATEFOLLOWED_SUCCESS',
    UPDATEFOLLOWED_FAILURE: 'USERS_UPDATEFOLLOWED_FAILURE',

    UPDATEBYID_REQUEST: 'USERS_UPDATEBYID_REQUEST',
    UPDATEBYID_SUCCESS: 'USERS_UPDATEBYID_SUCCESS',
    UPDATEBYID_FAILURE: 'USERS_UPDATEBYID_FAILURE',

    UPDATEPASSWORD_REQUEST: 'USERS_UPDATEPASSWORD_REQUEST',
    UPDATEPASSWORD_SUCCESS: 'USERS_UPDATEPASSWORD_SUCCESS',
    UPDATEPASSWORD_FAILURE: 'USERS_UPDATEPASSWORD_FAILURE',

    GETFAVORITESEARCHS_REQUEST: 'GETFAVORITESEARCHS_REQUEST',
    GETFAVORITESEARCHS_SUCCESS: 'GETFAVORITESEARCHS_SUCCESS',
    GETFAVORITESEARCHS_FAILURE: 'GETFAVORITESEARCHS_FAILURE',

    INIT_USER: 'INIT_USER',
};
