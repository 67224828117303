import Axios from 'axios';
import { authHeader, proxy } from '@helpers';
import { store } from '@store';

const axios = Axios;
export const postService = {
    create,
    getAll,
    getById,
    update,
    delete: deletePost,
    view,
    addToFavorite,
    removeToFavorite,
    getAllFavorites,
};

async function addToFavorite(userId, postId) {
    const response = await axios
        .put(`${proxy}/users/${userId}/favorites/${postId}`, {}, { headers: authHeader() })
        .then(() => {})
        .catch(function (error) {
            // alert("Impossible d'exécuter cette action! Veillez v reéssayer plus tard");
            console.error("Can't add to favorite", error);
        });
    return response;
}

async function removeToFavorite(userId, postId) {
    const response = await axios
        .delete(`${proxy}/users/${userId}/favorites/${postId}`, {
            headers: authHeader(),
        })
        .then(() => {})
        .catch(function (error) {
            //alert("Impossible d'exécuter cette action! Veillez reéssayer plus tard");
            console.error("Can't add to favorite", error);
        });
    return response;
}
//
async function dislikeAvalablePost(userId) {
    const response = await axios
        .delete(`${proxy}/users/${userId}/favorites`, {
            headers: authHeader(),
        })
        .then(() => {})
        .catch(function (error) {
            console.error("Can't revome to favorite:", error);
        });
    return response;
}

async function create(post) {
    const requestOptions = {
        method: 'POST',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(post),
    };

    const response = await fetch(`${proxy}/users/${post.ownerId}/${post.adressId}/products`, requestOptions);
    return handleResponse(response);
}

async function getAll(filter) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    const response = await fetch(`${proxy}/products?size=100&${filter ? filter : ''}`, requestOptions);
    return handleResponse(response);
}

async function getAllFavorites(filter, userId) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader(),
    };
    const response = await fetch(`${proxy}/users/${userId}/favorites?size=100&${filter ? filter : ''}`, requestOptions);
    return handleResponse(response);
}

async function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
    };

    const response = await fetch(`${proxy}/products/${id}`, requestOptions);
    return handleResponse(response);
}

async function update(post) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...authHeader(), 'Content-Type': 'application/json' },
        body: JSON.stringify(post),
    };

    const response = await fetch(`${proxy}/products/${post.id}`, requestOptions);
    return handleResponse(response);
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function deletePost(ownerId, ids) {
    const requestOptions = {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            ...authHeader(),
        },
        body: JSON.stringify(ids),
    };

    const response = await fetch(`${proxy}/users/${ownerId}/products`, requestOptions);
    return handleResponse(response);
}

async function handleResponse(response) {
    return await response.text().then((text) => {
        let data = text;
        try {
            data = text && JSON.parse(text);
        } catch (error) {
            console.error('cannot convert String to JSON', error);
        }
        if (!response.ok) {
            if (response.status == 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}

function view(id) {
    axios
        .post(`${proxy}/products/${id}/views`)
        .then(() => {})
        .catch((error) => {
            console.error("cann't increase views", error);
        });
}
