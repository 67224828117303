import React, { useEffect, useRef, useState } from 'react';
import { Animated, Platform, TouchableOpacity, Vibration, View } from 'react-native';
import { Icon, Text, TextInput } from '../../components';
import { BaseColor } from '../../config';
import { CheckBox } from 'react-native-web';
import { Switch } from 'react-native-gesture-handler';
import { showMessage } from 'react-native-flash-message';
import { scrollToId } from '../../helpers/functions';

export default function FormTextInput({ submitedInput, setInput, isLast, editable }) {
    const animationRef = useRef(new Animated.Value(0));

    const highlight = () => {
        scrollToId('submitedInput' + submitedInput.id);
        Vibration.vibrate(100);
        Animated.sequence([
            Animated.timing(animationRef.current, {
                toValue: 1,
                // set the duration
                duration: 500,
                useNativeDriver: false,
            }),
            // delay is optional
            Animated.delay(1000),
            Animated.timing(animationRef.current, {
                toValue: 0,
                duration: 500,
                // useNativeDriver:true, might not work with the all properties that you need to animate. true might improve animation performance
                useNativeDriver: false,
            }),
        ]).start();
    };

    useEffect(() => {
        if (editable && submitedInput.error) highlight();
    }, [submitedInput.checkInput]);

    return (
        <Animated.View
            style={{
                borderBottomColor: BaseColor.grayTransparent,
                borderBottomWidth: isLast ? 0 : 1,
                paddingBottom: 15,
                paddingTop: 10,
                paddingHorizontal: 15,
                backgroundColor: animationRef.current?.interpolate({
                    inputRange: [0, 1],
                    outputRange: ['#fff', '#ccc'],
                }),
            }}
        >
            <div id={'submitedInput' + submitedInput.id}>
                <View style={{ paddingBottom: 5 }}>
                    <Text>
                        {submitedInput.input?.label ?? 'Überschrift'}
                        {submitedInput.input?.required && ' *'}
                    </Text>
                    {submitedInput.input?.description && (
                        <Text grayColor footnote>
                            {submitedInput.input?.description}
                        </Text>
                    )}
                </View>
                <TextInput
                    editable={editable}
                    multiline={submitedInput.input?.multiline}
                    numberOfLines={submitedInput.input?.multiline ? 5 : 1}
                    onChangeText={(v) => setInput({ value: v })}
                    onFocus={() => setInput({ error: '' })}
                    placeholder={submitedInput.input?.placeholder ?? 'Ihre Antwort'}
                    success={!submitedInput.error}
                    value={submitedInput.value}
                    style={{ borderRadius: 0 }}
                    textError={submitedInput.error && 'Feld erforderlich'}
                />
            </div>
        </Animated.View>
    );
}
