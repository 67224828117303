import React, { useState } from 'react';
import { View, I18nManager, TextInput } from 'react-native';
import PropTypes from 'prop-types';
import { useTheme, useFont } from '@config';
import { BaseColor } from '../../config';
import { BaseStyle } from '../../config/styles';
import Text from '../Text';

export default function Index(props) {
    const { colors } = useTheme();
    const font = useFont();
    const cardColor = colors.card;
    const {
        style,
        success,
        icon,
        iconLeft,
        inputRef,
        textError,
        onFocus,
        autoComplete,
        onBlur,
        numberOfLines,
        multiline,
        fontSize,
        inputStyle,
        editable,
    } = props;
    const [state, setState] = useState({ borderColor: cardColor });
    function onInputFocus() {
        setState({
            borderColor: BaseColor.primary,
        });
        onFocus && onFocus();
    }
    function onInputBlur() {
        setState({
            borderColor: cardColor,
        });
        onBlur && onBlur();
    }
    return (
        <View>
            <View
                style={[
                    multiline ? BaseStyle.textInputArea : BaseStyle.textInput,
                    { backgroundColor: cardColor, borderStyle: 'solid', borderWidth: 1.3, borderColor: state.borderColor },
                    style,
                ]}
            >
                {iconLeft && <View style={{ paddingRight: 10 }}>{iconLeft}</View>}
                <TextInput
                    {...props}
                    style={[
                        {
                            fontFamily: font,
                            flex: 1,
                            height: '100%',
                            textAlign: I18nManager.isRTL ? 'right' : 'left',
                            color: success ? BaseColor.firstColor : BaseColor.errorColor,
                            paddingTop: multiline ? 10 : 5,
                            paddingBottom: 5,
                            textAlignVertical: multiline ? 'top' : 'auto',
                            fontSize: fontSize ?? 15,
                        },
                        inputStyle,
                    ]}
                    onFocus={onInputFocus}
                    onBlur={onInputBlur}
                    placeholderTextColor={success ? BaseColor.grayColor : BaseColor.errorColor}
                    selectionColor={BaseColor.primary}
                    cursorColor={BaseColor.primary}
                    ref={inputRef}
                    autoComplete={autoComplete}
                    {...(numberOfLines ? { numberOfLines: numberOfLines } : {})}
                    editable={editable ?? true}
                />
                {icon}
            </View>
            {textError?.length > 0 && (
                <Text footnote grayColor>
                    {textError}
                </Text>
            )}
        </View>
    );
}

Index.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    success: PropTypes.bool,
    icon: PropTypes.node,
};

Index.defaultProps = {
    style: {},
    success: true,
    icon: null,
};
