import { Platform, PixelRatio, Dimensions } from 'react-native';

const scaleValue = PixelRatio.get() / 2;

export const scaleWithPixel = (size, limitScale = 1.2) => {
    /* setting default upto 20% when resolution device upto 20% with defalt iPhone 7 */
    const value = scaleValue > limitScale ? limitScale : scaleValue;
    return size * value;
};

export const heightHeader = () => {
    const width = Dimensions.get('window').width;
    const height = Dimensions.get('window').height;
    const landscape = width > height;

    if (Platform.OS === 'android') return 45;
    if (Platform.isPad) return 65;
    switch (height) {
        case 812:
        case 844:
        case 896:
        case 926:
            return landscape ? 45 : 88;
        default:
            return landscape ? 45 : 65;
    }
};
export const getWidthDevice = () => {
    return Dimensions.get('window').width;
};
export const deviceDimentions = Dimensions.get('window');
