import React, { useCallback } from 'react';
import { View, ScrollView, RefreshControl } from 'react-native';
import { useTheme, Images } from '@config';
import { SafeAreaView, Icon, Text, Image } from '@components';
import styles from './styles';
import { connect } from 'react-redux';
import { BaseColor, BaseStyle } from '../../config';
import { useFocusEffect } from '@react-navigation/native';
import { Button, Header } from '../../components';
import * as SplashScreen from 'expo-splash-screen';
import { userActions } from '../../redux/actions';
import UserListItem from '../../components/UserListItem';

function UserList({ getUserAuth, getAllUser, allUser, navigation }) {
    const { colors } = useTheme();

    useFocusEffect(
        useCallback(() => {
            getUserAuth();
            onRefresh();
        }, [])
    );

    const onRefresh = () => {
        getAllUser('');
    };
    const renderList = () => {
        let list = (
            <View style={{ padding: 15 }}>
                {[1, 2, 3, 4, 5, 6].map((item, index) => {
                    return (
                        <View key={`ClientListItem ${index}`} style={{ width: '100%', paddingBottom: 20 }}>
                            <UserListItem loading={true} />
                        </View>
                    );
                })}
            </View>
        );
        if (allUser?.count >= 0) {
            list = (
                <View style={{ padding: 15 }}>
                    {allUser?.rows.map((item, index) => {
                        return (
                            <View key={`ClientListItem ${item?.id}`}>
                                <UserListItem navigation={navigation} item={item} />
                            </View>
                        );
                    })}
                </View>
            );
        }

        return <View style={{ paddingBottom: 80 }}>{list}</View>;
    };
    const onLayoutRootView = useCallback(async () => {
        await SplashScreen.hideAsync();
    }, []);
    const renderContent = () => {
        return (
            <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
                <Header
                    title="Alle Benutzer"
                    shadow
                    renderLeft={() => {
                        return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                    }}
                    onPressLeft={() => {
                        navigation.goBack();
                    }}
                />
                <ScrollView
                    style={{ flex: 1, backgroundColor: BaseColor.grayLight }}
                    refreshControl={<RefreshControl colors={[colors.primary]} tintColor={colors.primary} refreshing={false} onRefresh={onRefresh} />}
                >
                    {renderList()}
                </ScrollView>
            </View>
        );
    };

    return (
        <SafeAreaView style={[BaseStyle.safeAreaView]} edges={['right', 'left']}>
            {renderContent()}
            <View style={{ position: 'absolute', bottom: 0, width: '100%' }}>
                <View style={{ padding: 20, alignItems: 'center', justifyContent: 'center' }}>
                    <Button
                        style={{ height: 40, backgroundColor: 'white' }}
                        textColor={'black'}
                        icon={<Icon name="plus" size={25} type="ad" color={BaseColor.primary} />}
                        onPress={() => navigation.navigate('UserNew')}
                    >
                        {' '}
                        Benutzer hinzufügen
                    </Button>
                </View>
            </View>
        </SafeAreaView>
    );
}

function mapState(state) {
    const { user, all } = state.users;
    return { allUser: all, user };
}

const actionCreators = {
    getUserAuth: userActions.getUserAuth,
    getAllUser: userActions.getAll,
    deleteAction: userActions.delete,
};

export default connect(mapState, actionCreators)(UserList);
