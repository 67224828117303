import React, { useEffect } from 'react';
import { Dimensions, Platform } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator, CardStyleInterpolators } from '@react-navigation/stack';
import { useDispatch, useSelector } from 'react-redux';

/* Main Stack Navigator */
import Main from 'app/navigation/main';
/* Modal Screen only affect iOS */
import Loading from '@screens/Loading';
import AlertScreen from '../screens/Alert';
import SignIn from '@screens/SignIn';
import FlashMessage from 'react-native-flash-message';
import { userSelector } from '../selectors';
import { BaseColor } from '../config';
import { View } from 'react-native';

const RootStack = createStackNavigator();

export default function Navigator() {
    const user = useSelector(userSelector.user);
    const id = user?.id;
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
        }
    }, [id]);

    // useEffect(() => {
    //     if (Platform.OS === 'android') {
    //         StatusBar.setBarStyle('dark-content', true);
    //         StatusBar.setBackgroundColor(BaseColor.whiteColor, true);
    //         NavigationBar.setBackgroundColorAsync('white');
    //         NavigationBar.setButtonStyleAsync('dark');
    //     }

    //     AppState.addEventListener('change', (state) => {
    //         if (Platform.OS === 'android') {
    //             setTimeout(() => {
    //                 StatusBar.setBackgroundColor(BaseColor.whiteColor, true);
    //                 NavigationBar.setBackgroundColorAsync('white');
    //                 NavigationBar.setButtonStyleAsync('dark');
    //             }, 100);
    //         }
    //     });
    // }, []);

    return (
        <View style={{ flex: 1, backgroundColor: BaseColor.primary, flexDirection: 'row' }}>
            <View style={{ flex: 1 }}></View>
            <View>
                <View style={{ flex: 1 }}></View>
                <View
                    style={{
                        flex: 1,
                        minHeight: Math.min(800, Dimensions.get('window').height),
                        maxHeight: 800,
                        minWidth: Math.min(800, Dimensions.get('window').width),
                        backgroundColor: '#333',
                        overflow: 'hidden',
                        borderRadius: Dimensions.get('window').height >= 800 ? 20 : 0,
                    }}
                >
                    <View style={{ flex: 1, overflow: 'scroll' }}>
                        <NavigationContainer theme={{ colors: BaseColor }}>
                            <FlashMessage
                                duration={5000}
                                position="top"
                                style={{
                                    marginTop: Platform.OS == 'android' ? 15 : 20,
                                    marginHorizontal: 15,
                                    borderRadius: 30,
                                    opacity: 0.95,
                                    backgroundColor: '#444',
                                }}
                                textStyle={{ color: '#fff' }}
                                titleStyle={{ color: '#fff' }}
                            />
                            <RootStack.Navigator
                                screenOptions={{
                                    headerShown: false,
                                }}
                                initialRouteName="Loading"
                            >
                                <RootStack.Screen name="Loading" component={Loading} options={{ gestureEnabled: false }} />
                                <RootStack.Screen name="SignIn" component={SignIn} />
                                <RootStack.Screen
                                    name="Alert"
                                    component={AlertScreen}
                                    options={{
                                        presentation: 'transparentModal',
                                        cardStyleInterpolator: CardStyleInterpolators.forFadeFromCenter,
                                        cardStyle: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
                                        gestureEnabled: false,
                                    }}
                                />
                                <RootStack.Screen name="Main" component={id ? Main : SignIn} />
                            </RootStack.Navigator>
                        </NavigationContainer>
                    </View>
                </View>
                <View style={{ flex: 1 }}></View>
            </View>
            <View style={{ flex: 1 }}></View>
        </View>
    );
}
