import AsyncStorage from '@react-native-async-storage/async-storage';
import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunkMiddleware from 'redux-thunk';

import combineReducers from '@reducers';
import logger from 'redux-logger';

/**
 * Redux Setting
 */
if (process.env.NODE_ENV === `development`) {
    //AsyncStorage.clear();
}
const persistConfig = {
    key: 'root',
    storage: AsyncStorage,
    timeout: 100000,
};

let middleware = [thunkMiddleware];
if (process.env.NODE_ENV === `development`) {
    //middleware.push(logger);
}

const persistedReducer = persistReducer(persistConfig, combineReducers);
const store = createStore(persistedReducer, applyMiddleware(...middleware));
const persistor = persistStore(store);

export { store, persistor };
