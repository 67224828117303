import { combineReducers } from 'redux';
import { users } from './users.reducer';
import { posts } from './post.reducer';
import { offlineStorage } from './offlineStorage.reducer';
import { notifications } from './notification.reducer';
import { clients } from './client.reducer';
import { actions } from './action.reducer';
import { stores } from './store.reducer';

export default combineReducers({
    users,
    posts,
    offlineStorage,
    notifications,
    clients,
    actions,
    stores,
});
