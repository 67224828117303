import React from 'react';
import { View, TouchableHighlight, ActivityIndicator, Alert } from 'react-native';
import { Text, Icon } from '@components';
import { BaseColor } from '@config';
import { Placeholder, PlaceholderLine, Fade } from 'rn-placeholder';
import { connect } from 'react-redux';
import Menu from '../Menu';
import { actionActions, storeActions } from '../../redux/actions';
import { useState } from 'react';
import { formatDate2, formatDate3 } from '../../helpers/functions';

function StoreListItem(props) {
    const { item, loading, style, navigation, deleteStore, clickable = true, userId, role } = props;
    const [deleting, setDeleting] = useState();
    const isVisited = item?.status > 0;
    const deleteStoreAlert = (id) => {
        Alert.alert({
            title: item?.name + ' löschen',
            message: 'Möchten Sie diese Markt löschen?',
            type: 'error',
            action: [
                {
                    text: 'Löschen',
                    onPress: () => {
                        setDeleting((d) => ({ value: true, deleted: false }));
                        deleteStore(
                            id,
                            () => setDeleting((d) => ({ value: false, deleted: true })),
                            () => setDeleting((d) => ({ value: false, deleted: false }))
                        );
                    },
                },
                { text: 'Abbrechen', onPress: () => { } },
            ],
        });
    };
    const adminAction = () => (
        <View style={{ top: 0, right: 0, bottom: 15, position: 'absolute', justifyContent: 'center' }}>
            <Menu
                activator={() => (
                    <View
                        style={[
                            {
                                //backgroundColor: BaseColor.whiteColor,
                                height: 40,
                                width: 40,
                                //borderRadius: 30,
                                justifyContent: 'center',
                                alignItems: 'center',
                            },
                            //Platform.OS == 'android' && BaseStyle.boxWithShadowBottom,
                        ]}
                    >
                        <Icon name="more-vert" type="mi" size={20} color={BaseColor.text} enableRTL={true} />
                    </View>
                )}
                actionItems={[
                    {
                        text: 'Bearbeiten',
                        leftIcon: () => (
                            <View
                                style={{
                                    backgroundColor: BaseColor.grayLight,
                                    height: 45,
                                    width: 45,
                                    borderRadius: 15,
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <Icon name="edit" type="ad" size={18} color={BaseColor.text} />
                            </View>
                        ),
                        action: () => navigation.navigate({ name: 'StoreEdit', params: { store: item } }),
                    },
                    userId == 19 || !isVisited
                        ? {
                            text: 'Löschen',
                            leftIcon: () => (
                                <View
                                    style={{
                                        backgroundColor: BaseColor.grayLight,
                                        height: 45,
                                        width: 45,
                                        borderRadius: 15,
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Icon name="delete" type="ad" size={18} color={BaseColor.text} />
                                </View>
                            ),
                            action: () => deleteStoreAlert(item?.id),
                        }
                        : [],
                ]}
            />
        </View>
    );
    const renderGrid = () => {
        if (loading) {
            return (
                <View style={[style, { marginBottom: 5 }]}>
                    <Placeholder Animation={Fade}>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flex: 1 }}>
                                <PlaceholderLine style={{ height: 87, borderRadius: 0 }} />
                            </View>
                        </View>
                    </Placeholder>
                </View>
            );
        }
        return (
            <View>
                <TouchableHighlight
                    underlayColor={deleting?.deleted || !clickable ? '#fff' : '#00000010'}
                    activeOpacity={1}
                    style={{
                        overflow: 'hidden',
                        padding: 15,
                        borderBottomWidth: 1,
                        borderBottomColor: BaseColor.grayTransparent,
                        marginBottom: 13,
                        backgroundColor: BaseColor.whiteColor,
                        opacity: deleting?.deleted ? 0.5 : 1,
                    }}
                    onPress={() =>
                        !deleting?.deleted &&
                        clickable &&
                        navigation.navigate('StoreDetails', {
                            store: item,
                        })
                    }
                >
                    <View>
                        <View style={{ flexDirection: 'row' }}>
                            <View>
                                <View>
                                    <Text semibold>{item?.name}</Text>
                                </View>
                                {item.street && (
                                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <Text footnote>
                                            {item.street}, {item.postalCode} {item.city}
                                        </Text>
                                    </View>
                                )}
                                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                    {item.user ? (
                                        <Text footnote>
                                            {item?.user?.firstname} {item?.user?.lastname}
                                        </Text>
                                    ) : (
                                        <Text footnote errorColor>
                                            Nicht zugewiesen
                                        </Text>
                                    )}
                                </View>
                                {item.visitedDate && item.status > 0 && (
                                    <View style={{ alignItems: 'flex-start', paddingTop: 5 }}>
                                        <View
                                            style={{
                                                paddingHorizontal: 10,
                                                paddingVertical: 5,
                                                borderRadius: 20,
                                                backgroundColor: BaseColor.grayLight,
                                            }}
                                        >
                                            <Text footnote>Eingereicht am {item?.executionDate ?? formatDate2(item?.visitedDate)}</Text>
                                        </View>
                                    </View>
                                )}
                            </View>
                        </View>

                        {/* <View style={{ alignItems: 'flex-start', paddingTop: 5 }}>
                            <View style={{ paddingHorizontal: 10, paddingVertical: 5, borderRadius: 20, backgroundColor: BaseColor.grayLight }}>
                                <Text footnote></Text>
                            </View>
                        </View> */}
                        {role == 2 && clickable && (
                            <View style={{ position: 'absolute', top: 0, right: 0, bottom: 0 }}>
                                <View style={{ height: '100%', alignItems: 'flex-start', justifyContent: 'center' }}>
                                    <Icon name="chevron-right" type="mi" size={40} color={BaseColor.greenDark} enableRTL={true} />
                                </View>
                            </View>
                        )}
                    </View>
                </TouchableHighlight>
                <View
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        bottom: 13,
                        width: 3,
                        backgroundColor:
                            item.status == null || item.status == 0
                                ? BaseColor.errorColor
                                : item.status == '1'
                                    ? BaseColor.yellowColor
                                    : BaseColor.greenDark,
                    }}
                ></View>

                {role == 1 && !deleting?.deleted && adminAction()}
                {deleting?.value && (
                    <View style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 15 }}>
                        <View style={{ alignItems: 'center', justifyContent: 'center', flex: 1, backgroundColor: '#00000050' }}>
                            <ActivityIndicator size={'large'} color={BaseColor.whiteColor} />
                        </View>
                    </View>
                )}
            </View>
        );
    };

    return renderGrid();
}

function mapState(state) {
    const { user } = state.users;
    //const { deleting } = state.users?.all;
    return { role: user?.roles[0], userId: user.id };
}

const actionCreators = {
    deleteStore: storeActions.delete,
};

export default connect(mapState, actionCreators)(StoreListItem);
