import React, { useEffect, useRef, useState } from 'react';
import { View, SafeAreaView, KeyboardAvoidingView, TouchableOpacity } from 'react-native';
import { Icon, Text } from '@components';
import { connect } from 'react-redux';
import { BaseColor } from '../../config/theme';
import { Button, Header, TextInput } from '../../components';
import { BaseStyle } from '../../config';
import { Platform } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { showMessage } from 'react-native-flash-message';
import { clientActions, userActions } from '../../redux/actions';

function ProfileEdit({ navigation, updateUser, user, getAllClient }) {
    const offsetKeyboard = Platform.select({
        ios: 0,
        android: 60,
    });

    const [state, setState] = useState(user);
    const [passwordVisibility, setPasswordVisibility] = useState(false);
    const [confirmNewPasswordVisibility, setConfirmNewPasswordVisibility] = useState(false);

    useEffect(() => {
        getAllClient();
    }, []);
    const setForm = (name, value) => {
        setState((s) => ({ ...s, [name]: value }));
    };

    function handleSubmit(submitingPassword) {
        if (
            !(
                (!submitingPassword && !state.creating && state.firstname?.length > 0 && state.lastname?.length > 0) ||
                (submitingPassword &&
                    state.password?.length > 0 &&
                    state.newPassword?.length > 0 &&
                    state.confirmNewPassword?.length > 0 &&
                    state.newPassword === state.confirmNewPassword)
            )
        ) {
            setState((s) => ({
                ...s,
                firstnameError: state.firstname?.length > 0 ? '' : !submitingPassword ? 'Vorname erforderlich' : s.firstnameError,
                lastnameError: state.lastname?.length > 0 ? '' : !submitingPassword ? 'Nachname erforderlich' : s.lastnameError,
                passwordError: submitingPassword && state.password?.length > 0 ? '' : submitingPassword ? 'Password erforderlich' : s.passwordError,
                newPasswordError:
                    submitingPassword && state.newPassword?.length > 0 ? '' : submitingPassword ? 'Neues Password erforderlich' : s.newPasswordError,
                confirmNewPasswordError:
                    submitingPassword &&
                    state.newPassword?.length > 0 &&
                    state.confirmNewPassword?.length > 0 &&
                    state.newPassword != state.confirmNewPassword
                        ? 'Das eingegebene Passwort stimmt nicht'
                        : submitingPassword && state.confirmNewPassword?.length > 0
                        ? ''
                        : submitingPassword
                        ? 'Bitte neues Passwort bestätigen!'
                        : s.confirmNewPasswordError,
            }));
            return;
        }

        const { id, firstname, lastname, phonenumber, email, password, newPassword } = state;
        setForm(submitingPassword ? 'updatingPassword' : 'updatingInformations', true);
        updateUser(
            submitingPassword
                ? {
                      id,
                      password,
                      newPassword,
                  }
                : { id, firstname, lastname, phonenumber, email },
            () => {
                setForm(submitingPassword ? 'updatingPassword' : 'updatingInformations', false);
                if (submitingPassword) {
                    navigation.goBack();
                }
            },
            (error) => {
                setForm(submitingPassword ? 'updatingPassword' : 'updatingInformations', false);
                setForm('passwordError', error ?? '');
            }
        );
    }

    const lastnameInput = useRef(null);
    const passwordInput = useRef(null);
    const confirmPasswordInput = useRef(null);
    const newPasswordInput = useRef(null);
    const phonenumberInput = useRef(null);
    const emailInput = useRef(null);

    return (
        <View style={{ flex: 1, position: 'relative' }}>
            <Header
                title="Profil bearbeiten"
                shadow
                renderLeft={() => {
                    return <Icon name="arrowleft" size={25} type="ad" enableRTL={true} />;
                }}
                onPressLeft={() => {
                    navigation.goBack();
                }}
            />
            <SafeAreaView style={BaseStyle.safeAreaView} edges={['right', 'left']}>
                <KeyboardAvoidingView
                    behavior={Platform.OS === 'android' ? 'height' : 'padding'}
                    keyboardVerticalOffset={offsetKeyboard}
                    style={{ flex: 1, backgroundColor: BaseColor.grayLight }}
                >
                    <ScrollView>
                        <View style={{ padding: 20 }}>
                            <View
                                style={{
                                    borderBottomColor: BaseColor.grayTransparent,
                                    borderBottomWidth: 2,
                                    padding: 15,
                                    marginBottom: 20,
                                    backgroundColor: BaseColor.whiteColor,
                                }}
                            >
                                <View style={{ paddingBottom: 15 }}>
                                    <Text bold headline>
                                        Persönliche Informationen
                                    </Text>
                                </View>
                                <TextInput
                                    onChangeText={(v) => setForm('firstname', v)}
                                    onFocus={() => {
                                        setState({
                                            ...state,
                                            firstnameError: '',
                                        });
                                    }}
                                    placeholder="Vorname *"
                                    success={!state.firstnameError}
                                    value={state.firstname}
                                    style={{ height: 40, borderRadius: 0 }}
                                    onSubmitEditing={() => lastnameInput.current.focus()}
                                    textError={state.firstnameError}
                                />
                                <TextInput
                                    inputRef={lastnameInput}
                                    onChangeText={(v) => setForm('lastname', v)}
                                    onFocus={() => {
                                        setState({
                                            ...state,
                                            lastnameError: '',
                                        });
                                    }}
                                    placeholder="Nachname *"
                                    success={!state.lastnameError}
                                    value={state.lastname}
                                    style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                    onSubmitEditing={() => phonenumberInput.current.focus()}
                                    textError={state.lastnameError}
                                />

                                <TextInput
                                    inputRef={phonenumberInput}
                                    onChangeText={(v) => setForm('phonenumber', v)}
                                    onFocus={() => {
                                        setState({
                                            ...state,
                                            phonenumberError: '',
                                        });
                                    }}
                                    placeholder="Telefonnummer"
                                    value={state.phonenumber}
                                    style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                    onSubmitEditing={() => emailInput.current.focus()}
                                />

                                <TextInput
                                    autoComplete="email"
                                    inputRef={emailInput}
                                    onChangeText={(v) => setForm('email', v)}
                                    onFocus={() => {
                                        setState({
                                            ...state,
                                            emailError: '',
                                        });
                                    }}
                                    style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                    placeholder="E-Mail"
                                    name="email"
                                    textContentType="emailAddress"
                                    success={!state.emailError}
                                    value={state.email}
                                    onSubmitEditing={() => handleSubmit(false)}
                                />
                                <View style={{ alignItems: 'center', paddingTop: 15 }}>
                                    <Button
                                        full
                                        loading={state.updatingInformations}
                                        onPress={() => handleSubmit(false)}
                                        style={{ borderRadius: 0, height: 40, backgroundColor: BaseColor.text }}
                                    >
                                        {' '}
                                        Speichern
                                    </Button>
                                </View>
                            </View>
                            <View
                                style={{
                                    borderBottomColor: BaseColor.grayTransparent,
                                    borderBottomWidth: 2,
                                    padding: 15,
                                    backgroundColor: BaseColor.whiteColor,
                                }}
                            >
                                <View style={{ paddingBottom: 15 }}>
                                    <Text bold headline>
                                        Passwort ändern
                                    </Text>
                                </View>
                                <TextInput
                                    autoComplete="password"
                                    inputRef={passwordInput}
                                    onChangeText={(v) => setForm('password', v)}
                                    onFocus={() => {
                                        setState({
                                            ...state,
                                            passwordError: '',
                                        });
                                    }}
                                    style={{ height: 40, borderRadius: 0 }}
                                    placeholder="Aktuelles Passwort *"
                                    secureTextEntry={!passwordVisibility}
                                    name="password"
                                    textContentType="password"
                                    success={!state.passwordError}
                                    value={state.password}
                                    icon={
                                        <TouchableOpacity
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                paddingHorizontal: 5,
                                                height: 50,
                                            }}
                                            onPress={() => setPasswordVisibility((pw) => !pw)}
                                        >
                                            <Icon name={passwordVisibility ? 'eye' : 'eye-slash'} size={18} enableRTL={true} />
                                        </TouchableOpacity>
                                    }
                                    onSubmitEditing={() => newPasswordInput.current.focus()}
                                    textError={state.passwordError}
                                />
                                <TextInput
                                    inputRef={newPasswordInput}
                                    onChangeText={(v) => setForm('newPassword', v)}
                                    onFocus={() => {
                                        setState({
                                            ...state,
                                            newPasswordError: '',
                                        });
                                    }}
                                    style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                    placeholder="Neues Passwort *"
                                    secureTextEntry={true}
                                    name="newPassword"
                                    textContentType="newPassword"
                                    success={!state.newPasswordError}
                                    value={state.newPassword}
                                    onSubmitEditing={() => confirmPasswordInput.current.focus()}
                                    textError={state.newPasswordError}
                                />
                                <TextInput
                                    inputRef={confirmPasswordInput}
                                    onChangeText={(v) => setForm('confirmNewPassword', v)}
                                    onFocus={() => {
                                        setState({
                                            ...state,
                                            confirmNewPasswordError: '',
                                        });
                                    }}
                                    style={{ marginTop: 15, height: 40, borderRadius: 0 }}
                                    placeholder="Neues Passwort bestätigen *"
                                    secureTextEntry={!confirmNewPasswordVisibility}
                                    name="confirmNewPassword"
                                    textContentType="confirmNewPassword"
                                    success={!state.confirmNewPasswordError}
                                    value={state.confirmNewPassword}
                                    icon={
                                        <TouchableOpacity
                                            style={{
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                paddingHorizontal: 5,
                                                height: 50,
                                            }}
                                            onPress={() => setConfirmNewPasswordVisibility((pw) => !pw)}
                                        >
                                            <Icon name={confirmNewPasswordVisibility ? 'eye' : 'eye-slash'} size={18} enableRTL={true} />
                                        </TouchableOpacity>
                                    }
                                    textError={state.confirmNewPasswordError}
                                    onSubmitEditing={() => handleSubmit(true)}
                                />
                                <View style={{ alignItems: 'center', paddingTop: 15 }}>
                                    <Button
                                        full
                                        loading={state.updatingPassword}
                                        onPress={() => handleSubmit(true)}
                                        style={{ borderRadius: 0, height: 40, backgroundColor: BaseColor.text }}
                                    >
                                        {' '}
                                        Speichern
                                    </Button>
                                </View>
                            </View>
                        </View>
                    </ScrollView>
                </KeyboardAvoidingView>
            </SafeAreaView>
        </View>
    );
}

function mapState(state) {
    const { user } = state.users;
    const { all } = state.clients;
    const { creating } = state.users.all;
    return { user, creating, allClient: all };
}

const actionCreators = {
    updateUser: userActions.update,
    getAllClient: clientActions.getAll,
};

export default connect(mapState, actionCreators)(ProfileEdit);
