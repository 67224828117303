export const notificationConstants = {
    GET_MESSAGE_REQUEST: 'GET_MESSAGE_NOTIFICATION_REQUEST',
    GET_FAVORITE_REQUEST: 'GET_FAVORITE_NOTIFICATION_REQUEST',
    GET_ALERT_REQUEST: 'GET_ALERT_NOTIFICATION_REQUEST',
    GET_ALL_REQUEST: 'GET_ALL_NOTIFICATION_REQUEST',

    GET_MESSAGE_SUCCESS: 'GET_MESSAGE_NOTIFICATION_SUCCESS',
    GET_FAVORITE_SUCCESS: 'GET_FAVORITE_NOTIFICATION_SUCCESS',
    GET_ALERT_SUCCESS: 'GET_ALERT_NOTIFICATION_SUCCESS',
    GET_ALL_SUCCESS: 'GET_ALL_NOTIFICATION_SUCCESS',

    GET_MESSAGE_FAILURE: 'GET_MESSAGE_NOTIFICATION_FAILURE',
    GET_FAVORITE_FAILURE: 'GET_FAVORITE_NOTIFICATION_FAILURE',
    GET_ALERT_FAILURE: 'GET_ALERT_NOTIFICATION_FAILURE',
    GET_ALL_FAILURE: 'GET_ALL_NOTIFICATION_FAILURE',

    NEW_MESSAGE: 'NEW_MESSAGE_NOTIFICATION',
    NEW_FAVORITE: 'NEW_FAVORITE_NOTIFICATION',
    NEW_ALERT: 'NEW_ALERT_NOTIFICATION',
    NEW_All: 'NEW_ALL_NOTIFICATION',

    DELETE_MESSAGE_REQUEST: 'DELETE_MESSAGE_NOTIFICATION_REQUEST',
    DELETE_FAVORITE_REQUEST: 'DELETE_FAVORITE_NOTIFICATION_REQUEST',
    DELETE_ALERT_REQUEST: 'DELETE_ALERT_NOTIFICATION_REQUEST',

    DELETE_MESSAGE_SUCCESS: 'DELETE_MESSAGE_NOTIFICATION_SUCCESS',
    DELETE_FAVORITE_SUCCESS: 'DELETE_FAVORITE_NOTIFICATION_SUCCESS',
    DELETE_ALERT_SUCCESS: 'DELETE_ALERT_NOTIFICATION_SUCCESS',

    DELETE_MESSAGE_FAILURE: 'DELETE_MESSAGE_NOTIFICATION_FAILURE',
    DELETE_FAVORITE_FAILURE: 'DELETE_FAVORITE_NOTIFICATION_FAILURE',
    DELETE_ALERT_FAILURE: 'DELETE_ALERT_NOTIFICATION_FAILURE',

    LOGOUT_SUCCESS: "NOTIFICATION_LOGOUT_SUCCESS"
};
