import React, { useState } from 'react';
import { View, TouchableOpacity, TouchableHighlight, ScrollView } from 'react-native';
import styles from './styles';
import Text from '../Text';
import Modal from 'react-native-modal';
import { BaseColor } from '../../config';

export default function Menu({ style, activator, actionItems, placeholder, component, activatorStyle, visibile }) {
    const [state, setstate] = useState({ modalVisible: false, action: null });

    const onApply = (action) => {
        setstate((s) => ({ ...s, modalVisible: false /*action: action*/ }));
        function delay(time) {
            return new Promise((resolve) => setTimeout(resolve, time));
        }

        delay(500).then(() => action && action());
    };
    // const action = () => {
    //     state.action && state.action();
    //     setstate((s) => ({ ...s, action: null }));
    // };
    return (
        <View style={activatorStyle}>
            <View style={[styles.contain, { backgroundColor: BaseColor.background }, style]}>
                <Modal
                    isVisible={state.modalVisible}
                    //onModalHide={action}
                    onSwipeComplete={() => {
                        setstate((s) => ({ ...s, modalVisible: false }));
                        action();
                    }}
                    backdropTransitionOutTiming={0}
                    hideModalContentWhileAnimating
                    onBackdropPress={() => setstate((s) => ({ ...s, modalVisible: false }))}
                    onBackButtonPress={() => setstate((s) => ({ ...s, modalVisible: false }))}
                    swipeDirection={['down']}
                    style={styles.bottomModal}
                    propagateSwipe
                >
                    <View style={[styles.contentFilterBottom, { backgroundColor: BaseColor.whiteColor }]}>
                        <View style={styles.contentSwipeDown}>
                            <View style={styles.lineSwipeDown} />
                        </View>
                        {actionItems ? (
                            <ScrollView>
                                {placeholder && (
                                    <TouchableOpacity
                                        style={[
                                            styles.contentActionModalBottom,
                                            { paddingHorizontal: 20, borderBottomColor: BaseColor.border, alignItems: 'center' },
                                        ]}
                                        key={item.text}
                                        onPress={() => onApply(item.action)}
                                    >
                                        <View style={{ flexDirection: 'row' }}>
                                            {item.leftIcon && <View style={{ paddingRight: 20 }}>{item.leftIcon()}</View>}
                                            <View style={{ justifyContent: 'center' }}>
                                                <Text subhead grayColor>
                                                    {item.text}
                                                </Text>
                                            </View>
                                        </View>
                                        {item.icon && <View>{item.icon()}</View>}
                                    </TouchableOpacity>
                                )}
                                {actionItems.map(
                                    (item, index) =>
                                        item.text && (
                                            <TouchableHighlight
                                                underlayColor={item.disable ? BaseColor.whiteColor : BaseColor.grayLight}
                                                activeOpacity={1}
                                                style={[
                                                    styles.contentActionModalBottom,
                                                    {
                                                        paddingHorizontal: 20,
                                                        borderBottomColor: BaseColor.grayLight,
                                                        alignItems: 'center',
                                                        opacity: item.disable ? 0.5 : 1,
                                                    },
                                                ]}
                                                key={item.text + index}
                                                onPress={() => !item.disable && onApply(item.action)}
                                            >
                                                <View style={{ flexDirection: 'row' }}>
                                                    {item.leftIcon && (
                                                        <View
                                                            style={{
                                                                marginRight: 10,
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            {item.leftIcon()}
                                                        </View>
                                                    )}
                                                    <View style={{ justifyContent: 'center', flex: 1 }}>
                                                        <Text callout1>{item.text}</Text>
                                                        {item.textSecond && (
                                                            <Text caption1 grayColor>
                                                                {item.textSecond}
                                                            </Text>
                                                        )}
                                                    </View>
                                                    {item.rightIcon && (
                                                        <View
                                                            style={{
                                                                marginRight: 10,
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                            }}
                                                        >
                                                            {item.rightIcon()}
                                                        </View>
                                                    )}
                                                </View>
                                            </TouchableHighlight>
                                        )
                                )}
                            </ScrollView>
                        ) : (
                            component && (
                                <ScrollView style={{ paddingHorizontal: 15 }}>
                                    <TouchableOpacity activeOpacity={1}>{component()}</TouchableOpacity>
                                </ScrollView>
                            )
                        )}
                    </View>
                </Modal>
            </View>
            {(visibile == true || visibile == null) && (
                <TouchableOpacity activeOpacity={0.7} onPress={() => setstate((s) => ({ ...s, modalVisible: true }))}>
                    {activator && activator()}
                </TouchableOpacity>
            )}
        </View>
    );
}
