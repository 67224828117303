import { userConstants } from '../constants';

let initialState = {
    isLoggedIn: false,
    user: null,
    all: [],
};

export function users(state = initialState, action) {
    switch (action.type) {
        case 'INIT':
            return { ...initialState, user: action.logout ? null : state.user };
        case userConstants.INIT_USER:
            return {
                ...state,
                user: action.user,
            };
        case userConstants.GETALL_REQUEST:
            return {
                ...state,
                all: { ...state.all, loading: true },
            };
        case userConstants.GETALL_SUCCESS:
            return {
                ...state,
                all: action.users,
            };
        case userConstants.GETALL_FAILURE:
            return {
                ...state,
                all: { ...state.all, error: action.error, loading: false },
            };
        case userConstants.DELETE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
                ...state,
                all: { ...state.all, rows: state.all.rows?.map((user) => (action.id == user.id ? { ...user, deleting: true } : user)) },
            };
        case userConstants.DELETE_SUCCESS:
            // remove deleted user from state
            return {
                ...state,
                all: {
                    count: state.all.count > 0 ? state.all.count - 1 : 0,
                    rows: state.all.rows?.filter((user) => action.id != user.id),
                },
            };
        case userConstants.DELETE_FAILURE:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user
            return {
                ...state,
                all: {
                    ...state.all,
                    rows: state.all.rows?.map((user) => (action.id == user.id ? { ...user, deleting: false, deletingError: action.error } : user)),
                },
            };

        //Register
        case userConstants.REGISTER_REQUEST:
            return {
                ...state,
                all: {
                    ...state.all,
                    creating: true,
                },
            };
        case userConstants.REGISTER_SUCCESS:
            return { ...state, all: { count: state.all.count + 1, rows: [...state.all.rows, action.user], creating: false } };
        case userConstants.REGISTER_FAILURE:
            return {
                ...state,
                all: {
                    ...state.all,
                    createError: action.error,
                    creating: false,
                },
            };

        //Login
        case userConstants.LOGIN_REQUEST:
            return { ...state, logging: true };
        case userConstants.LOGIN_SUCCESS:
            return {
                ...state,
                logging: false,
                user: { ...(state.user ?? {}), ...action?.user, roles: action?.user?.roles.map((r) => r.value) },
                isLoggedIn: true,
            };
        case userConstants.LOGIN_FAILURE:
            return { ...state, logging: false };

        //Logout
        case userConstants.LOGOUT_REQUEST:
            return { ...state, loggingOut: true };
        case userConstants.LOGOUT_SUCCESS:
            return { ...state, loggingOut: false, isLoggedIn: false, user: null };
        case userConstants.LOGOUT_FAILURE:
            return { loggingOut: false, isLoggedIn: false };

        //Get By Id
        case userConstants.GETBYID_REQUEST:
            return { ...state, loading: true };
        case userConstants.GETBYID_SUCCESS:
            return { ...state, [action.userId]: action.user };
        case userConstants.GETBYID_FAILURE:
            return { ...state, error: action.error, loading: false };

        //Update User
        case userConstants.UPDATEBYID_REQUEST:
            return {
                ...state,
                [action.user?.id]: { ...state[action.user?.id], updating: true },
            };
        case userConstants.UPDATEBYID_SUCCESS:
            return {
                ...state,
                [action.user?.id]: action.user,
                user: { ...state.user, ...action.user },
            };
        case userConstants.UPDATEBYID_FAILURE:
            return {
                ...state,
                [action.user?.id]: {
                    ...state[action.user?.id],
                    error: action.error,
                    updating: false,
                },
            };

        //Update User
        case userConstants.GETFAVORITESEARCHS_REQUEST:
            return {
                ...state,
                favoriteSearchsLoading: true,
            };
        case userConstants.GETFAVORITESEARCHS_SUCCESS:
            return {
                ...state,
                favoriteSearchs: action.favoriteSearchs || [],
                favoriteSearchsLoading: false,
            };
        case userConstants.GETFAVORITESEARCHS_FAILURE:
            return {
                ...state,
                ...state.user,
                favoriteSearchsLoading: false,
            };
        default:
            return state;
    }
}
