import { clientConstants } from '../constants';
import { clientService } from '../services';
import { showMessage } from 'react-native-flash-message';

export const clientActions = {
    create,
    update,
    getAll,
    delete: _delete,
    getSingle,
};

function create(client, callback, errorCallback) {
    return (dispatch) => {
        dispatch(request({ client }));
        clientService.create(client).then(
            (client) => {
                dispatch(success(client));
                callback && callback(client);
            },
            (error) => {
                dispatch(failure(error.toString()));
                errorCallback && errorCallback(error);
            }
        );
    };

    function request(client) {
        return { type: clientConstants.CREATE_REQUEST, client };
    }
    function success(client) {
        return { type: clientConstants.CREATE_SUCCESS, client };
    }
    function failure(error) {
        return { type: clientConstants.CREATE_FAILURE, error };
    }
}

function update(client, callback, errorCallback) {
    return (dispatch) => {
        dispatch(request({ client }));
        clientService.update(client).then(
            (client) => {
                dispatch(success(client));
                callback && callback(client);
            },
            (error) => {
                dispatch(failure(error.toString()));
                errorCallback && errorCallback(error);
            }
        );
    };

    function request(client) {
        return { type: clientConstants.UPDATE_REQUEST, client };
    }
    function success(client) {
        return { type: clientConstants.UPDATE_SUCCESS, client };
    }
    function failure(error) {
        return { type: clientConstants.UPDATE_FAILURE, error };
    }
}

function getAll(userId, callback) {
    return (dispatch) => {
        dispatch(request());
        clientService.getAll(userId).then(
            (clients) => {
                dispatch(success(clients));
                callback && callback();
            },
            (error) => {
                dispatch(failure(error.toString()));
            }
        );
    };

    function request() {
        return { type: clientConstants.GETALL_REQUEST };
    }
    function success(clients) {
        return { type: clientConstants.GETALL_SUCCESS, clients };
    }
    function failure(error) {
        return { type: clientConstants.GETALL_FAILURE, error };
    }
}

function _delete(id, callback) {
    return (dispatch) => {
        dispatch(request(id));
        clientService.delete(id).then(
            (resp) => {
                showMessage({
                    message: 'Kunde wurde gelöscht',
                    icon: 'success',
                });
                callback && callback();
                dispatch(success(id));
            },
            (error) => {
                showMessage({
                    message: 'Fehler beim Löschen',
                    icon: 'warning',
                });
                dispatch(failure(id, error.toString()));
            }
        );
    };

    function request(id) {
        return { type: clientConstants.DELETE_REQUEST, id };
    }
    function success(id) {
        return { type: clientConstants.DELETE_SUCCESS, id };
    }
    function failure(id, error) {
        return { type: clientConstants.DELETE_FAILURE, id, error };
    }
}

function getSingle(id, callback) {
    return (dispatch) => {
        dispatch(request(id));
        clientService.getById(id).then(
            (client) => {
                dispatch(success(id, client));
                callback && callback(client);
            },
            (error) => dispatch(failure(id, error.toString()))
        );
    };

    function request(id) {
        return { type: clientConstants.GETBYID_REQUEST, id };
    }
    function success(id, client) {
        return { type: clientConstants.GETBYID_SUCCESS, id, client };
    }
    function failure(id, error) {
        return { type: clientConstants.GETBYID_FAILURE, id, error };
    }
}
