import { useEffect, useState } from 'react';
import { ActivityIndicator, View, StatusBar } from 'react-native';
import { BaseColor } from '../../config';
import { compareDate, dateWatch } from '../../helpers/functions';
import * as NavigationBar from 'expo-navigation-bar';
import { Text } from '..';

const LoadingFullscreen = ({ text }) => {
    // useEffect(() => {
    //     StatusBar.setBarStyle('light-content', true);
    //     StatusBar.setBackgroundColor('#000000aa');
    //     NavigationBar.setBackgroundColorAsync('#000000aa');
    //     NavigationBar.setButtonStyleAsync('light');
    //     return () => {
    //         StatusBar.setBarStyle('dark-content', true);
    //         StatusBar.setBackgroundColor('white');
    //         NavigationBar.setBackgroundColorAsync('white');
    //         NavigationBar.setButtonStyleAsync('dark');
    //     };
    // }, []);

    return (
        <View
            style={[
                {
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 0,
                    height: '100%',
                    flex: 1,
                    backgroundColor: '#000000aa',
                },
            ]}
        >
            <ActivityIndicator size={'large'} color={BaseColor.primary} style={{ marginBottom: 20 }} />
            <View style={{ backgroundColor: '#ffffff10', paddingHorizontal: 10, borderRadius: 20, paddingVertical: 5 }}>
                <Text whiteColor>{text||"Bitte warten..."}</Text>
            </View>
        </View>
    );
};

export default LoadingFullscreen;
