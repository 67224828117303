import { authHeader, proxy } from '@helpers';

export const userService = {
    login,
    logout,
    register,
    getAll,
    getById,
    getByIdAuth,
    update,
    delete: _delete,
    changePassword,
    getFavoriteSearchs,
    getStatistics,
};

async function login(user) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    };
    return await fetch(`${proxy}/login`, requestOptions).then(handleResponse);
}

async function logout(userId) {
    // remove user from local storage to log user out
    const requestOptions = {
        method: 'GET',
        headers: await authHeader(),
    };

    const response = await fetch(`${proxy}/users`, requestOptions);
    return handleResponse(response);
}

async function getAll(filter) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader(),
    };

    const response = await fetch(`${proxy}/users?${filter}`, requestOptions);
    return handleResponse(response);
}

async function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader(),
    };
    const response = await fetch(`${proxy}/users/${id}`, requestOptions);
    return handleResponse(response);
}

async function getStatistics(id) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader(),
    };
    const response = await fetch(`${proxy}/users/${id}/statistics`, requestOptions);
    return handleResponse(response);
}

async function getFavoriteSearchs(id) {
    const requestOptions = {
        method: 'GET',
        headers: await authHeader(),
    };
    const response = await fetch(`${proxy}/users/${id}/favoriteSearchs`, requestOptions);
    return handleResponse(response);
}
async function getByIdAuth(id) {
    const requestOptions = {
        method: 'POST',
        headers: await authHeader(),
    };
    const response = await fetch(`${proxy}/authenticate`, requestOptions);
    return handleResponse(response);
}

async function register(user) {
    const requestOptions = {
        method: 'POST',
        headers: { ...(await authHeader()), 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    };
    return await fetch(`${proxy}/signup`, requestOptions).then(handleResponse);
}

async function update(user) {
    const requestOptions = {
        method: 'PATCH',
        headers: { ...(await authHeader()), 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    };

    const response = await fetch(`${proxy}/users`, requestOptions);
    return handleResponse(response);
}

async function changePassword(user) {
    const requestOptions = {
        method: 'PUT',
        headers: { ...(await authHeader()), 'Content-Type': 'application/json' },
        body: JSON.stringify(user),
    };

    const response = await fetch(`${proxy}/auth/users/${user?.id}/changePassword`, requestOptions);
    return handleResponse(response);
}

// prefixed function name with underscore because delete is a reserved word in javascript
async function _delete(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: await authHeader(),
    };

    const response = await fetch(`${proxy}/users/${id}`, requestOptions);
    return handleResponse(response);
}

async function handleResponse(response) {
    return await response.text().then((text) => {
        if (!response.ok) {
            if (response.status == 401) {
                // auto logout if 401 response returned from api
                //logout();
                //location.reload(true);
            }
            const error = (data && data.message) || response.statusText;
            return Promise.reject({ error: error, status: response.status, ...(text && JSON.parse(text)) });
        }
        const data = text && JSON.parse(text);

        return data;
    });
}
