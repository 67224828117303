import React, { useEffect } from 'react';
import { Text, Icon } from '@components';
import { TouchableOpacity, View, BackHandler } from 'react-native';
import { useTheme, BaseColor } from '@config';
import styles from './styles';

export default function Alert({ route, navigation }) {
    const { colors } = useTheme();
    const { title, message, action, option, type } = route?.params;
    const success = type === 'success';

    useEffect(() => {
        const backHandler = BackHandler.addEventListener('hardwareBackPress', () => !option?.cancelable);

        return () => backHandler.remove();
    }, [option?.cancelable]);

    const renderButtonFirst = () => {
        const firstTitle = action?.[0]?.text ?? 'OK';
        const onPress = action?.[0]?.onPress;
        return (
            <TouchableOpacity
                style={styles.button}
                onPress={() => {
                    onPress?.();
                    if (option?.cancelable) navigation.goBack();
                }}
            >
                <Text body2 semibold>
                    {firstTitle}
                </Text>
            </TouchableOpacity>
        );
    };

    const renderButtonSecond = () => {
        const secondTitle = action?.[1]?.text;
        const onPress = action?.[1]?.onPress;
        if (title && onPress) {
            return (
                <TouchableOpacity
                    style={[
                        styles.button,
                        {
                            borderLeftColor: BaseColor.grayTransparent,
                            borderLeftWidth: 1,
                        },
                    ]}
                    onPress={() => {
                        onPress?.();
                        if (option?.cancelable) navigation.goBack();
                    }}
                >
                    <Text body2 semibold>
                        {secondTitle}
                    </Text>
                </TouchableOpacity>
            );
        }
    };

    return (
        <View style={styles.contain}>
            <View style={[styles.content, { backgroundColor: BaseColor.whiteColor }]}>
                <View
                    style={[
                        styles.contentIcon,
                        {
                            backgroundColor: BaseColor.grayTransparent,
                        },
                    ]}
                >
                    <Icon
                        name={success ? 'check-circle' : type == 'error' ? 'exclamation-circle' : 'info-circle'}
                        size={28}
                        color={BaseColor.primary}
                    />
                </View>
                <View style={{ padding: 8, alignItems: 'center' }}>
                    {title && (
                        <Text body1 medium>
                            {title}
                        </Text>
                    )}
                    {message && (
                        <Text subhead style={styles.message}>
                            {message}
                        </Text>
                    )}
                </View>
                <View style={[styles.contentButton, { borderTopColor: BaseColor.grayTransparent }]}>
                    {renderButtonFirst()}
                    {renderButtonSecond()}
                </View>
            </View>
        </View>
    );
}
