export const offlineStorageConstants = {
    SET_HEADER: 'SET_HEADER',
    SET_BOTTOM_NAV_POINTER: 'SET_BOTTOM_NAV_POINTER',
    CHANGE_LR: 'CHANGE_LR',
    UPDATE_NEW_POST_STATE: 'UPDATE_NEW_POST_STATE',
    UPDATE_NEW_POST_IMAGE: 'UPDATE_NEW_POST_IMAGE',
    SELECT_NEW_POST_IMAGE: 'SELECT_NEW_POST_IMAGE',
    UNSELECT_NEW_POST_IMAGE: 'UNSELECT_NEW_POST_IMAGE',
    UNSELECT_ALL_NEW_POST_IMAGE: 'UNSELECT_ALL_NEW_POST_IMAGE',
    ADD_TO_NEW_POST_IMAGE: 'ADD_TO_NEW_POST_IMAGE',
    REMOVE_TO_NEW_POST_IMAGE: 'REMOVE_TO_NEW_POST_IMAGE',
    REMOVE_DUPLICATE_TO_NEW_POST_IMAGE: 'REMOVE_DUPLICATE_TO_NEW_POST_IMAGE',
    PUT_TO_COVER: 'PUT_TO_COVER',
    SAVE_ALBUMS: 'SAVE_ALBUMS',
    SAVE_ALBUM_IMAGES: 'SAVE_ALBUM_IMAGES',
    LOGOUT_SUCCESS: 'OFFLINESTORAGE_LOGOUT_SUCCESS',
    UPDATE_EXPOPUSHTOKEN: 'UPDATE_EXPOPUSHTOKEN',
    SIMPLE_UPDATE: 'OFFLINE_STORAGE_SIMPLE_UPDATE',
};
