import { userConstants } from '../constants';
import { userService } from '../services';
import { showMessage } from 'react-native-flash-message';
import { postConstants } from '../constants/post.constants';

export const userActions = {
    login,
    logout,
    register,
    getAll,
    getUser,
    getUserAuth,
    update,
    delete: _delete,

    initUser,
    getFavoriteSearchs,
    clearStorage,

    getStatistics,
};

function initUser(user) {
    dispatch(() => ({
        type: userConstants.INIT_USER,
        user,
    }));
}

function logout(callback) {
    return (dispatch) => {
        callback && callback();
        dispatch(clearStorage(true));
    };
    function request() {
        return { type: userConstants.LOGOUT_REQUEST };
    }
    function success() {
        return { type: userConstants.LOGOUT_SUCCESS };
    }
    function failure(error) {
        return { type: userConstants.LOGOUT_FAILURE, error };
    }
}

function register(user, successCallBack, errorCallback) {
    return (dispatch) => {
        dispatch(request(user));
        userService.register(user).then(
            (resp) => {
                successCallBack && successCallBack(resp);
                dispatch(getAll());
                showMessage({
                    message: 'Benutzer erfolgreich eingefügt',
                    icon: 'success',
                });
            },
            (error) => {
                errorCallback && errorCallback(error);
                showMessage({
                    message: error.message ?? 'Fehler beim Eingefügen',
                    icon: 'error',
                });
                dispatch(failure(error.status));
            }
        );
    };

    function request(user) {
        return { type: userConstants.REGISTER_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.REGISTER_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.REGISTER_FAILURE, error };
    }
}

function login(user, setState) {
    return (dispatch) => {
        dispatch(request(user));
        userService.login(user).then(
            (respUser) => {
                dispatch(success(respUser));
                setState &&
                    setState((s) => ({
                        ...s,
                        successMessage: 'Anmeldung erfolgreich',
                        submited: false,
                        errorMessage: null,
                        firstnameError: null,
                        lastnameError: null,
                        passwordError: null,
                    }));
            },
            (resp) => {
                dispatch(failure(resp.status));
                setState &&
                    setState((s) => ({
                        ...s,
                        errorMessage:
                            resp.status == 401 || resp.status == 404
                                ? 'Eingaben nicht korrekt'
                                : 'Ein unerwarteter Fehler ist aufgetreten! Überprüfen Sie Ihre Verbindung oder versuchen Sie es später erneut',
                        submited: false,
                        successMessage: null,
                        firstnameError: null,
                        lastnameError: null,
                        passwordError: null,
                    }));
            }
        );
    };

    function request(user) {
        return { type: userConstants.LOGIN_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.LOGIN_SUCCESS, user };
    }
    function failure(error) {
        return { type: userConstants.LOGIN_FAILURE, error };
    }
}

function getAll(filter) {
    return (dispatch) => {
        dispatch(request());

        userService.getAll(filter).then(
            (users) => dispatch(success(users)),
            (error) => dispatch(failure(error.toString()))
        );
    };

    function request() {
        return { type: userConstants.GETALL_REQUEST };
    }
    function success(users) {
        return { type: userConstants.GETALL_SUCCESS, users };
    }
    function failure(error) {
        return { type: userConstants.GETALL_FAILURE, error };
    }
}

function _delete(id, callbackSuccess, callbackFailure) {
    return (dispatch) => {
        dispatch(request(id));
        userService.delete(id).then(
            (user) => {
                dispatch(success(id));
                callbackSuccess && callbackSuccess(user);
            },
            (error) => {
                dispatch(failure(id, error.toString()));
                callbackFailure && callbackFailure(error);
            }
        );
    };

    function request(id) {
        return { type: userConstants.DELETE_REQUEST, id };
    }
    function success(id) {
        return { type: userConstants.DELETE_SUCCESS, id };
    }
    function failure(id, error) {
        return { type: userConstants.DELETE_FAILURE, id, error };
    }
}

function getUser(userId) {
    return (dispatch) => {
        dispatch(request(userId));
        userService.getById(userId).then(
            (user) => {
                dispatch(success(userId, user));
            },
            (error) => {
                dispatch(failure(userId, error.toString()));
            }
        );
    };

    function request(userId) {
        return { type: userConstants.GETBYID_REQUEST, userId };
    }
    function success(userId, user) {
        return { type: userConstants.GETBYID_SUCCESS, userId, user };
    }
    function failure(userId, error) {
        return { type: userConstants.GETBYID_FAILURE, userId, error };
    }
}
function getUserAuth(callBack) {
    return (dispatch) => {
        userService.getByIdAuth().then(
            (user) => {
                dispatch(loginSuccess(user));
                callBack && callBack();
            },
            (resp) => {
                if (resp.status == 401) {
                    dispatch(logout(callBack));
                } else {
                    callBack && callBack();
                }
            }
        );
    };

    function loginSuccess(user) {
        return {
            type: userConstants.LOGIN_SUCCESS,
            user,
        };
    }
}

function getFavoriteSearchs(userId, callBack, callBackError) {
    return (dispatch) => {
        dispatch(request(userId));
        userService.getFavoriteSearchs(userId).then(
            (favoriteSearchs) => {
                dispatch(success(userId, favoriteSearchs));
                callBack && callBack();
            },
            (resp) => {
                if (!callBackError & callBack) callBack();
                else callBackError && callBackError(resp);
                dispatch(failure(userId, user));
            }
        );
    };

    function request(userId) {
        return { type: userConstants.GETFAVORITESEARCHS_REQUEST, userId };
    }
    function success(userId, favoriteSearchs) {
        return { type: userConstants.GETFAVORITESEARCHS_SUCCESS, userId, favoriteSearchs };
    }
    function failure(userId, error) {
        return { type: userConstants.GETFAVORITESEARCHS_FAILURE, userId, error };
    }
}

function update(user, callbackSuccess, callbackError) {
    return (dispatch) => {
        dispatch(request(user));
        userService.update(user).then(
            (u) => {
                dispatch(success(u));
                callbackSuccess && callbackSuccess();
                showMessage({
                    message: 'Speichern erfolgreich',
                    icon: 'success',
                    type: 'success',
                });
            },
            (error) => {
                callbackError && callbackError(error?.error);
                console.error(error);
                //dispatch(failure(user, error.toString()));
                showMessage({
                    message: 'Speichern nicht möglich!',
                    description: error.error,
                    icon: 'warning',
                    type: 'warning',
                });
            }
        );
    };

    function request(user) {
        return { type: userConstants.UPDATEBYID_REQUEST, user };
    }
    function success(user) {
        return { type: userConstants.UPDATEBYID_SUCCESS, user };
    }
    function failure(user, error) {
        return { type: userConstants.UPDATEBYID_FAILURE, user, error };
    }
}

function clearStorage(logout, callback) {
    return (dispatch) => {
        dispatch({ type: 'INIT', logout });
        callback && callback();
    };
}

function getStatistics(id, request, success, failure) {
    request();
    userService.getStatistics(id).then(
        (response) => success(response),
        (error) => failure(error)
    );
}
